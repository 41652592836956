import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { map, takeUntil } from "rxjs";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";
import { TableColumn } from "../../table/table.component";
import { ColumnsSelectorService } from "./columns-selector.service";

@Component({
    selector: 'columns-selector',
    templateUrl: `./columns-selector.component.html`,
    styleUrls: ['./columns-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class ColumnsSelectorComponent extends DestroyableSubscription implements OnInit, AfterViewInit {

    @Input() columns: TableColumn[];

    columnsForm: FormGroup;

    constructor(private _fb: FormBuilder, private _columnsSelectorService: ColumnsSelectorService,
        private _elementRef: ElementRef) {
        super();
    }

    ngOnInit(): void {
        this.columnsForm = this._fb.group({
            columns: new FormControl<string[]>(this._columnsSelectorService.selectedColumns$.value)
        });

        this.columnsForm.valueChanges.pipe(
            map(formValue => formValue.columns),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(columns => this._columnsSelectorService.selectedColumns$.next(columns));
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('columns-selector');
    }
}