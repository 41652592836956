import { HttpHeaders } from "@angular/common/http";
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/services/auth.service";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";

@Component({
    selector: 'albi-image-wrapper',
    templateUrl: `./albi-image-wrapper.component.html`,
    styleUrls: ['./albi-image-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AlbiImageWrapperComponent implements AfterViewInit {

    public albiImageWrapperDictionary = COMPONENTS_DICTIONARY.detail.albi_image_wrapper

    @Input() imageUrl: string;
    @Input() vintageId: string;
    @Input() imagePath: string;

    @Input() imageTitle: string;
    @Input() imageDescription: string;
    @Input() imageResolution: string;
    @Input() imageFormat: string;

    @Input() imagePlaceHolderWidth: number;
    @Input() imagePlaceHolderHeigh: number;

    public endpointString$ = new BehaviorSubject(null);
    public endpointHeaders$ = new BehaviorSubject<HttpHeaders>(null);

    constructor(private _elementRef: ElementRef, private _authService: AuthService) { }

    ngAfterViewInit(): void {
        this.endpointString$.next(`https://api-dev.albicchiere.com/vintages/${this.vintageId}/${this.imagePath}`);
        this.endpointHeaders$.next(new HttpHeaders({ "Authorization": `Bearer ${this._authService.getSessionInfo().accessToken}` }))
        this._elementRef.nativeElement.classList.add('albi-image-wrapper');
    }

    onDefaultLabelUpload(val: any) {
        console.log(val)
    }
}