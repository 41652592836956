<ng-container *ngIf="{
    visibleColumns: tableColumns$ | async,
} as vm">
    <p-table [value]="items" selectionMode="multiple" (selectionChange)="selectedItemsChange($event)"
        [scrollable]="true" scrollHeight="flex" [scrollDirection]="'both'" styleClass="mt-3" dataKey="_id">
        <ng-template pTemplate="header">
            <tr>
                <th pFrozenColumn class="table-checkbox-row-item">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <ng-container *ngFor="let column of vm.visibleColumns">
                    <th *ngIf="column.isSortable" [pSortableColumn]="column.name" [class]="column.cellStyleWidth">
                        {{column.name}}
                        <p-sortIcon [field]="column.name"></p-sortIcon>
                    </th>
                    <th *ngIf="!column.isSortable" [class]="column.cellStyleWidth">{{column.name}}</th>
                </ng-container>
                <th class="detail-button-container" alignFrozen="right" pFrozenColumn [frozen]="true"></th>
                <!-- header relative to the button that links the detail pagez-->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" [pSelectableRowIndex]="item._id">
                <td pFrozenColumn class="table-checkbox-row-item">
                    <p-tableCheckbox [pSelectableRow]="item" [value]="item"></p-tableCheckbox>
                </td>
                <ng-container [ngTemplateOutlet]="rowItemTemplate" [ngTemplateOutletContext]="{$implicit: item}">
                </ng-container>
                <td class="detail-button-container" alignFrozen="right" pFrozenColumn [frozen]="true">
                    <p-button icon="pi pi-eye" styleClass="p-button-text"
                        [pTooltip]="tableDictionary.detail_button_tooltip | translate" [tooltipPosition]="'top'"
                        (onClick)="handleDetailPageButton(item._id)"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-paginator (onPageChange)="onPageChange($event)" [rows]="pageInfo.size"
        [first]="(pageInfo.page - 1)*pageInfo.size" [totalRecords]="pageInfo.size * pageInfo.pages"
        [rowsPerPageOptions]="[10, 25, 50]"></p-paginator>
</ng-container>