
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, delay } from 'rxjs';
import { LOGIN_PAGE_ACTIONS } from './store/login.actions';
import { LOGIN_SELECTORS } from './store/login.selectors';

export type LoginFormType = {
    username: FormControl<string | null>,
    password: FormControl<string | null>
}


@Component({
    selector: 'login-component',
    templateUrl: `./login.component.html`,
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements AfterViewInit {
    public isLoading$: Observable<boolean>;

    public loginForm: FormGroup<LoginFormType>;

    public seePassword: boolean


    constructor(private _store: Store, private _elementRef: ElementRef) {

        this.seePassword = false
        this.loginForm = new FormGroup<LoginFormType>({
            username: new FormControl<string | null>('', Validators.required),//lucasarge4@gmail.com
            password: new FormControl<string | null>('', Validators.required)//Qazplm700!
        });
        this.isLoading$ = this._store.select(LOGIN_SELECTORS.selectIsLoading).pipe(
            delay(300)
        );
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('login-container');
    }

    onSubmit() {
        this._store.dispatch(LOGIN_PAGE_ACTIONS.clickLogin(this.loginForm.value as { username: string, password: string }));
    }

    handleSeePassword() {
        this.seePassword = !this.seePassword
    }

}