<div class="dashboard-header">
    <p-image src="assets/glass.png" alt="Image" width="40"></p-image>

    <p-menubar [model]="tabsItems$ | async">
    </p-menubar>

    <div class="user-container">
        <p-overlayPanel #op>
            <p-button icon="pi pi-sign-out" [label]="dashboardDictionary.logout_button | translate"
                (onClick)="logout()"></p-button>
        </p-overlayPanel>
        <span>{{dashboardDictionary.avatar_text | translate}}</span>
        <p-button icon="pi pi-user" (onClick)="op.toggle($event)"
            styleClass="p-button-rounded p-button-info p-button-outlined"></p-button>
    </div>

</div>
<div class="tab-content">
    <router-outlet></router-outlet>
</div>