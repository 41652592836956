export const WINE_DICTIONARY = {
    field_label: {
        full_name: 'wine.field_label.full_name',
        name: 'wine.field_label.name',
        id: 'wine.field_label.id',
        color: 'wine.field_label.color',
        winery_id: 'wine.field_label.winery_id',
        country: 'wine.field_label.country',
        region: 'wine.field_label.region',
        status: 'wine.field_label.status',
        grapes: 'wine.field_label.grapes',
        is_claimed_by_winery: 'wine.field_label.is_claimed_by_winery',
        description: 'wine.field_label.description',
        acidity: 'wine.field_label.acidity',
        fizziness: 'wine.field_label.fizziness',
        intensity: 'wine.field_label.intensity',
        sweetness: 'wine.field_label.sweetness',
        tannin: 'wine.field_label.tannin',
        wine_types: 'wine.field_label.wine_types',
        created_at: 'wine.field_label.created_at',
        created_by: 'wine.field_label.created_by',
        updated_at: 'wine.field_label.updated_at',
        updated_by: 'wine.field_label.updated_by',
        percentage: 'wine.field_label.percentage',
    },
    field_values: {
        color: {
            red: 'wine.field_values.color.red',
            rose: 'wine.field_values.color.rose',
            white: 'wine.field_values.color.white'
        },
        wine_profile: {
            acidity: 'wine.field_values.wine_profile.acidity',
            fizziness: 'wine.field_values.wine_profile.fizziness',
            intensity: 'wine.field_values.wine_profile.intensity',
            tannin: 'wine.field_values.wine_profile.tannin',
            sweetness: 'wine.field_values.wine_profile.sweetness',
        },
        wine_types: {
            sparkling: 'wine.field_values.wine_types.sparkling',
            sweet: 'wine.field_values.wine_types.sweet',
            fortified: 'wine.field_values.wine_types.fortified',
            firm: 'wine.field_values.wine_types.firm',
        },
    },
    list: {
        table_title_label: 'wine.list.table_title_label',
        table_new_item: 'wine.list.table_new_item',
    },
    detail: {
        tabs: {
            properties: 'wine.detail.tabs.properties',
            vintages: 'wine.detail.tabs.vintages',
            logs: 'wine.detail.tabs.logs'
        },
        properties: {
            wine_detail: {
                title: 'wine.detail.properties.wine_detail.title',
                wine_spec: {
                    title: 'wine.detail.properties.wine_detail.wine_spec.title',
                    wine_name_label: 'wine.detail.properties.wine_detail.wine_spec.wine_name_label',
                    wine_color_label: 'wine.detail.properties.wine_detail.wine_spec.wine_color_label',
                    wine_type_label: 'wine.detail.properties.wine_detail.wine_spec.wine_type_label',
                    country_label: 'wine.detail.properties.wine_detail.wine_spec.country_label',
                    region_label: 'wine.detail.properties.wine_detail.wine_spec.region_label',
                    grape_label: 'wine.detail.properties.wine_detail.wine_spec.grape_label',
                }
                ,
                description: {
                    title: 'wine.detail.properties.wine_detail.description.title',
                    master_lang_label: 'wine.detail.properties.wine_detail.description.master_lang_label',
                    not_found_text: 'wine.detail.properties.wine_detail.description.not_found_text',
                },
            },
            save_panel: {
                submit_button: 'wine.detail.properties.save_panel.submit_button',
            },
            ratings: {
                title: 'wine.detail.properties.ratings.title',
                intensity_text: 'wine.detail.properties.ratings.intensity_text',
                acidity_text: 'wine.detail.properties.ratings.acidity_text',
                sweetnes_text: 'wine.detail.properties.ratings.sweetnes_text',
                tannin_text: 'wine.detail.properties.ratings.tannin_text',
                fizziness_text: 'wine.detail.properties.ratings.fizziness_text',
            },
            wine_profile_not_found: 'wine.detail.properties.wine_profile_not_found'
        },
        vintages: {
            table_title_label: 'wine.detail.vintages.table_title_label',
            table_new_item: 'wine.detail.vintages.table_new_item',
        },
    },
    main_page: {
        tabs: {
            wines: 'wine.main_page.tabs.wines',
            vintages: 'wine.main_page.tabs.vintages'
        }
    }
}