export const USER_TRANSLATIONS_EN = {
    user: {
        field_label: {
            name: 'Name',
            lastname: 'Lastname',
            email: 'Email',
            id: 'Id',
            gender: 'Gender',
            age: 'Age',
            nation: 'Country',
            percentage: 'Percentage',
            birthday: 'Birthday',
            nationality: 'Nationality',
            locale: 'Locale',
            created_at: 'Created at',
            updated_at: 'Updated at',
            policy_consent: 'Policy consent',
            marketing_consent: 'Marketing consent',
        },
        field_values: {
            gender: {
                male: 'Male',
                female: 'Female',
                nd: 'Nd',
            },
        },
        list: {
            table_title_label: 'Users',
            table_new_item: 'New User',
        },
        detail: {
            left_panel: {
                register_label: 'Registered',
                gps: 'GPS',
            },
            right_panel: {
                active_services: 'Active Services'
            },
            tabs: {
                properties: 'Properties',
                consumption: 'Consumption',
                orders: 'Orders',
                activities: 'Activities',
                wine: 'Wine',
            },
            properties: {
                personal_info: {
                    title: 'Personal info',
                    private_info: {
                        title: 'Private info',
                        name: 'Name',
                        surname: 'Surname',
                        email: 'Email',
                        gender: 'Gender',
                        birthdate: 'Birthdate',
                        nationality: 'Nationality',
                    },
                    contacts: {
                        title: 'Contacts',
                        phone: 'Phone',
                        cell: 'Cell',
                    },
                    account_settings: {
                        title: 'Account settings',
                        language: 'Language',
                        measure: 'Measure',
                        hour_format: 'Hour format',
                        date_format: 'Date format',
                    },
                    notification_settings: {
                        title: 'Notification settings',
                        general_push: 'General push notification ',
                        general_email: 'General email notification ',
                        device_push: 'Device push notification ',
                        device_email: 'Device email notification ',
                    },
                    privacy_settings: {
                        title: 'Privacy settings',
                        basic_auth: 'Basic authorization',
                        marketing_auth: 'Marketing authorization',
                        newsletter_subscription: 'Newsletter subscription',
                        handle: 'Handle',
                    },
                },
                addressess: {
                    billing: 'Billing',
                    shipping: 'Shipping',
                    first_address_line: 'Address 1',
                    second_address_line: 'Address 2',
                    city: 'City',
                    zip: 'ZIP',
                    state: 'State',
                    country: 'Country',
                    phone: 'Phone',
                },
                bottom_save_bar: {
                    save_button: 'Save'
                }
            },
            wine: {
                personal_cantina: {
                    title: 'Personal Cantina',
                    field_label: {
                        id: 'Id',
                        vintage: 'Vintage',
                        winery: 'Winery',
                        availability: 'Availability',
                        score: 'Score',
                        locked: 'Locked',
                    },
                    field_values: {
                        availability: {
                            quantity_text: '{{quantity}} x ',
                            bottles: 'Bottles',
                            smartbags: 'Smartbags',
                        },
                    }
                },
                user_vintages: {
                    title: 'User Vintages',
                    field_label: {
                        id: 'Id',
                        full_name: 'Full name',
                        winery: 'Winery',
                        locked: 'Locked',
                        label: 'Label',
                    },
                    field_values: {
                        full_name: '{{wine}} {{year}}',
                    }
                },
            },
        }
    }
}