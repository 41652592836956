export const VINTAGE_DICTIONARY = {
    field_label: {
        full_name: 'vintage.field_label.full_name',
        id: 'vintage.field_label.id',
        wine_id: 'vintage.field_label.wine_id',
        year: 'vintage.field_label.year',
        year_details: 'vintage.field_label.year_details',
        alcohol: 'vintage.field_label.alcohol',
        temperature: 'vintage.field_label.temperature',
        description: 'vintage.field_label.description',
        status: 'vintage.field_label.status',
        moods: 'vintage.field_label.moods',
        pairings: 'vintage.field_label.pairings',
        allergen: 'vintage.field_label.allergen',
        philosophy: 'vintage.field_label.philosophy',
        is_claimed_by_winery: 'vintage.field_label.is_claimed_by_winery',
        label_search: 'vintage.field_label.label_search',
        created_at: 'vintage.field_label.created_at',
        updated_at: 'vintage.field_label.updated_at',
        percentage: 'vintage.field_label.percentage',
        allergens: 'vintage.field_label.allergens',
        free_so2: 'vintage.field_label.free_so2',
        awards: 'vintage.field_label.awards',
        ratings_average: 'vintage.field_label.ratings_average',
        created_by: 'vintage.field_label.created_by',
        updated_by: 'vintage.field_label.updated_by',
    },
    field_values: {},
    list: {
        table_title_label: 'vintage.list.table_title_label',
        table_new_item: 'vintage.list.table_new_item',
        filter_missing_images: {
            name: 'vintage.list.filter_missing_images.name',
            options: {
                all: 'vintage.list.filter_missing_images.options.all',
                bottle: 'vintage.list.filter_missing_images.options.bottle',
                dispenser: 'vintage.list.filter_missing_images.options.dispenser',
                smart_bag: 'vintage.list.filter_missing_images.options.smart_bag',
                label: 'vintage.list.filter_missing_images.options.label',
            }
        },
    },
    detail: {
        tabs: {
            properties: 'vintage.detail.tabs.properties',
            other_vintages: 'vintage.detail.tabs.other_vintages'
        },
        properties: {
            details: {
                title: 'vintage.detail.properties.details.title',
                specs: {
                    title: 'vintage.detail.properties.details.specs.title',
                    year_label: 'vintage.detail.properties.details.specs.year_label',
                    service_temperature_label: 'vintage.detail.properties.details.specs.service_temperature_label',
                    alcohol_label: 'vintage.detail.properties.details.specs.alcohol_label',
                },
                description: {
                    title: 'vintage.detail.properties.details.description.title',
                    paragraph: 'vintage.detail.properties.details.description.paragraph',
                    not_found_text: 'vintage.detail.properties.details.description.not_found_text',
                },
            },
            pairings: {
                title: 'vintage.detail.properties.pairings.title',
                foods: {
                    title: 'vintage.detail.properties.pairings.foods.title',
                    primary_pairings_label: 'vintage.detail.properties.pairings.foods.primary_pairings_label',
                    secondary_pairings_label: 'vintage.detail.properties.pairings.foods.secondary_pairings_label',
                },
                others: {
                    title: 'vintage.detail.properties.pairings.others.title',
                    moods_label: 'vintage.detail.properties.pairings.others.moods_label',
                    allergens_label: 'vintage.detail.properties.pairings.others.allergens_label',
                    philosophy_label: 'vintage.detail.properties.pairings.others.philosophy_label',
                },
                awards: {
                    title: 'vintage.detail.properties.pairings.awards.title',
                    awards_label: 'vintage.detail.properties.pairings.awards.awards_label',
                },
            },
            images: {
                title: 'vintage.detail.properties.images.title',
                label_image_title: 'vintage.detail.properties.images.label_image_title',
                smartbag_image_title: 'vintage.detail.properties.images.smartbag_image_title',
                bottle_image_title: 'vintage.detail.properties.images.bottle_image_title',
                others_title: 'vintage.detail.properties.images.others_title',
                others_description: 'vintage.detail.properties.images.others_description',

            },
            save_panel: {
                submit_button: 'vintage.detail.properties.save_panel.submit_button',
            },
        },
    },
    create: {
        page_title: 'vintage.create.page_title',
        page_description: 'vintage.create.page_description',
        steps: {
            winery: 'vintage.create.steps.winery',
            wine: 'vintage.create.steps.wine',
            vintage: 'vintage.create.steps.vintage',
            recap: 'vintage.create.steps.recap',
        },
        button_labels: {
            next: 'vintage.create.button_labels.next',
            prev: 'vintage.create.button_labels.prev',
            save: 'vintage.create.button_labels.save',
        },
        errors: {
            required_field: 'vintage.create.errors.required_field',
            value_error: 'vintage.create.errors.value_error',
        },
        winery: {
            step_title: 'vintage.create.winery.step_title',
            autocomplete_title: 'vintage.create.winery.autocomplete_title',
            autocomplete_description: 'vintage.create.winery.autocomplete_description',
            create_new_label: 'vintage.create.winery.create_new_label',
            autocomplete_item: {
                name: 'vintage.create.winery.autocomplete_item.name',
                type: 'vintage.create.winery.autocomplete_item.type',
            },
        },
        wine: {
            step_title: 'vintage.create.wine.step_title',
            autocomplete_title: 'vintage.create.wine.autocomplete_title',
            autocomplete_description: 'vintage.create.wine.autocomplete_description',
            create_new_label: 'vintage.create.wine.create_new_label',
            wine_info: {
                title: 'vintage.create.wine.wine_info.title'
            },
            autocomplete_item: {
                name: 'vintage.create.wine.autocomplete_item.name',
                color: 'vintage.create.wine.autocomplete_item.color',
            },
        },
        vintage: {
            step_title: 'vintage.create.vintage.step_title',
            year_picker_title: 'vintage.create.vintage.year_picker_title',
            year_picker_description: 'vintage.create.vintage.year_picker_description',
            special_definition_label: 'vintage.create.vintage.special_definition_label',
            non_vintage: 'vintage.create.vintage.non_vintage',
            vintage_details: {
                title: 'vintage.create.vintage.vintage_details.title',
                description_label: 'vintage.create.vintage.vintage_details.description_label',
                language_select_label: 'vintage.create.vintage.vintage_details.language_select_label'
            },
        },
        recap: {
            description_label: 'vintage.create.recap.description_label',
        }
    }
}