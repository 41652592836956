<form [formGroup]="columnsForm">
    <p-listbox [options]="[columns[0]]" formControlName="columns" optionLabel="name" optionValue="key"
        [showToggleAll]="false" [multiple]="true" [style]="{ width: '15rem' }" [disabled]="true" [readonly]="true">
        <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2">
                <i class="pi pi-lock" style="font-size: 20px"></i>
                <div>{{ item.name }}</div>
            </div>
        </ng-template>
    </p-listbox>
    <p-listbox [options]="columns.slice(1)" [checkbox]="true" formControlName="columns" optionLabel="name"
        optionValue="key" [showToggleAll]="false" [multiple]="true" [style]="{ width: '15rem' }"></p-listbox>
</form>