export const DASHBOARD_DICTIONARY = {
    tabs: {
        wines: 'dashboard.tabs.wines',
        vintages: 'dashboard.tabs.vintages',
        companies: 'dashboard.tabs.companies',
        users: 'dashboard.tabs.users',
        dispensers: 'dashboard.tabs.dispensers',
        tags: 'dashboard.tabs.tags',
        smartbags: 'dashboard.tabs.smartbags',
        bottles: 'dashboard.tabs.bottles',
    },
    avatar_text: 'dashboard.avatar_text',
    logout_button: 'dashboard.logout_button',
}