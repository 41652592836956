import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from "@angular/core";

export enum AlbiInputWrapperDirectionEnum {
    ROW = 'row',
    COLUMN = 'column'
};
export enum AlbiInputWrapperWidthEnum {
    FULL = 'full',
    HALF = 'half',
    QUARTER = 'quarter',
};

@Component({
    selector: 'albi-input-wrapper',
    templateUrl: `./albi-input-wrapper.component.html`,
    styleUrls: ['./albi-input-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AlbiInputWrapper implements AfterViewInit {

    @Input() elementTitle: string;

    @Input() direction: AlbiInputWrapperDirectionEnum = AlbiInputWrapperDirectionEnum.ROW;
    @Input() width: AlbiInputWrapperWidthEnum = AlbiInputWrapperWidthEnum.HALF;
    @Input() isFluidWrapper: boolean = true;

    @Input() customCssTitle: object;
    @Input() customCssWrapper: object;

    constructor(private _elementRef: ElementRef,) {
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add(
            ...[
                'albi-input-wrapper',
                (this.direction === AlbiInputWrapperDirectionEnum.ROW ?
                    'albi-input-wrapper--row' : 'albi-input-wrapper--column'),
                (this.width === AlbiInputWrapperWidthEnum.HALF ?
                    'albi-input-wrapper--half-width' : this.width === AlbiInputWrapperWidthEnum.FULL ? 'albi-input-wrapper--full-width' : 'albi-input-wrapper--quarter-width')
            ]);
    }
}
