import { createSelector } from '@ngrx/store';
import { LoginState } from './login.state';

const selectLogin = (state: any): LoginState => state.login;

export const LOGIN_SELECTORS = {
    selectToken: createSelector(
        selectLogin,
        (state) => state.token
    ),
    selectInitFromStorageDone: createSelector(
        selectLogin,
        (state) => state.initFromStorageDone
    ),

    selectError: createSelector(
        selectLogin,
        (state) => state.error
    ),

    selectIsLoading: createSelector(
        selectLogin,
        (state) => state.isLoading
    ),
}

