export const DISPENSER_DICTIONARY = {
    field_label: {
        id: 'dispenser.field_label.id',
        alert: 'dispenser.field_label.alert',
        model: 'dispenser.field_label.model',
        name: 'dispenser.field_label.name',
        serial: 'dispenser.field_label.serial',
        firmware: 'dispenser.field_label.firmware',
        status: 'dispenser.field_label.status',
        mode: 'dispenser.field_label.mode',
        battery_status: 'dispenser.field_label.battery_status',
        owner_id: 'dispenser.field_label.owner_id',
        paired_at: 'dispenser.field_label.paired_at',
        bag_id: 'dispenser.field_label.bag_id',
        thing_name: 'dispenser.field_label.thing_name',
        percentage: 'dispenser.field_label.percentage',
        paired_by: 'dispenser.field_label.paired_by',
    },
    field_values: {
        model: {
            "home-m+": 'dispenser.field_values.model.home-m+',
            "home-m": 'dispenser.field_values.model.home-m',
            pro: 'dispenser.field_values.model.pro',
        },
        firmware: {
            "1-0-0-00": 'dispenser.field_values.firmware.1-0-0-00',
            "1-0-1-00": 'dispenser.field_values.firmware.1-0-1-00',
            "1-1-0-00": 'dispenser.field_values.firmware.1-1-0-00'
        },
        alert: {
            pouring: 'dispenser.field_values.alert.pouring',
            pairing: 'dispenser.field_values.alert.pairing',
            temperature: 'dispenser.field_values.alert.temperature',
            scan_tag: 'dispenser.field_values.alert.scan_tag',
        },
    },
    list: {
        table_title_label: 'dispenser.list.table_title_label',
        table_new_item: 'dispenser.list.table_new_item',
    },
    detail: {
        header: {
            bag_inside: 'dispenser.detail.header.bag_inside',
        },
        tabs: {
            properties: 'dispenser.detail.tabs.properties',
            logs: 'dispenser.detail.tabs.logs',
            performances: 'dispenser.detail.tabs.performances',
        },
        properties: {
            about: {
                title: 'dispenser.detail.properties.about.title',
                info: {
                    title: 'dispenser.detail.properties.about.info.title',
                    dispenser_name: 'dispenser.detail.properties.about.info.dispenser_name',
                    mac_address: 'dispenser.detail.properties.about.info.mac_address',
                    id: 'dispenser.detail.properties.about.info.id',
                    warranty: 'dispenser.detail.properties.about.info.warranty',
                    serial_number: 'dispenser.detail.properties.about.info.serial_number',
                },
                specs: {
                    title: 'dispenser.detail.properties.about.specs.title',
                    model: 'dispenser.detail.properties.about.specs.model',
                    cooling_mode: 'dispenser.detail.properties.about.specs.cooling_mode',
                    firmware: 'dispenser.detail.properties.about.specs.firmware',
                    last_firmware_update: 'dispenser.detail.properties.about.specs.last_firmware_update',
                },
                gear: {
                    title: 'dispenser.detail.properties.about.gear.title',
                    battery_status: 'dispenser.detail.properties.about.gear.battery_status'
                },
            },
            save_button: 'dispenser.detail.properties.save_button',
        }
    }
}