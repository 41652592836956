import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { saveAs } from 'file-saver';
import { MessageService } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { PaginatorState } from "primeng/paginator";
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, filter, of, switchMap, take, takeUntil, tap } from "rxjs";
import { FeatureService } from "src/services/feature.service";
import { LocalStorageService } from "src/services/local-storage.service";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";
import { TableColumn } from "../table/table.component";
import { ColumnsSelectorService } from "./columns-selector/columns-selector.service";
import { FilterChoiceOptionType, FilterConfig, FilterPreset, FilterTypeEnum } from "./models/filters.model";
import { ExportService } from "./services/export.service";
import { AlbiMenuItem } from "./speed-dial-button/speed-dial-button.component";
import { DATA_BROWSER_GENERIC_ACTIONS, DATA_BROWSER_PAGE_ACTIONS } from "./store/data-browser.actions";
import { DATA_BROWSER_SELECTORS, DATA_BROWSER_SELECTORS_TYPE } from "./store/data-browser.selectors";
import { DataBrowserState, PageInfo } from "./store/data-browser.state";
export type DataBrowserConfig = { endpoint: string; responseField: string, searchField: string; }

@Component({
    selector: 'data-browser',
    templateUrl: `./data-browser.component.html`,
    styleUrls: ['./data-browser.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService]
})
export class DataBrowserWrapperComponent extends DestroyableSubscription implements OnInit, AfterViewInit, OnDestroy {
    public dataBrowserDictionary = COMPONENTS_DICTIONARY.data_browser

    private _DATA_BROWSER_SELECTORS: DATA_BROWSER_SELECTORS_TYPE;

    private _DATA_BROWSER_PAGE_ACTIONS;

    @Input() titleLabel: string;
    @Input() newItemLabel: string;
    @Input() columns: TableColumn[];
    @Input() presettedFilteredView: FilterPreset[];

    @Input() rowItemTemplate: TemplateRef<any>;

    @Input() filtersConfig: FilterConfig[];

    @Input() mainSelectorFilter?: {
        filterLabel: string;
        filterField: string;
        options: FilterChoiceOptionType[];
    };

    @Input() speedDialItems?: AlbiMenuItem[];

    @ViewChild('exportPanel') exportPanel: OverlayPanel;

    searchText: string | null = null;

    FilterTypeEnum = FilterTypeEnum;

    isLoading$: Observable<boolean>;

    isColumnsSelecotorVisible$: Observable<boolean>;
    isFilterVisible: Observable<boolean>;

    items$: Observable<any[]>;
    pageInfo$: Observable<PageInfo>;

    selectedFilteredview$ = new BehaviorSubject<FilterPreset>(null);

    selectedItems$: Observable<any[]>;
    public filters$: Observable<any>;
    public readonly featureName: string;
    public filteredViewList$ = new BehaviorSubject<FilterPreset[]>([]);

    public saveFilteredView = new FormGroup({
        filterViewName: new FormControl<string>('')
    })

    public readonly exportExtensionOptions: any[] = [{ label: 'Json', value: 'json' }, { label: 'Csv', value: 'csv' }];
    public exportExtension: string = this.exportExtensionOptions[0].value;

    constructor(private _elementRef: ElementRef, private _featureService: FeatureService, private _router: Router,
        private _store: Store<DataBrowserState>, private messageService: MessageService, private _columnsSelectorService: ColumnsSelectorService,
        public _exportService: ExportService, private _localStorageService: LocalStorageService) {
        super();
        this._DATA_BROWSER_SELECTORS = DATA_BROWSER_SELECTORS(this._featureService.getFeatureName());
        this._DATA_BROWSER_PAGE_ACTIONS = DATA_BROWSER_PAGE_ACTIONS(this._featureService.getFeatureName());
        this.featureName = this._featureService.getFeatureName();
    }

    ngOnInit(): void {
        this.isLoading$ = this._store.select(this._DATA_BROWSER_SELECTORS.selectIsLoading);
        this.selectedItems$ = this._store.select(this._DATA_BROWSER_SELECTORS.selectSelectedItems)

        this.isColumnsSelecotorVisible$ = this._store.select(this._DATA_BROWSER_SELECTORS.selectIsColumnsSelecotorVisible);
        this.isFilterVisible = this._store.select(this._DATA_BROWSER_SELECTORS.selectIsFilterVisible);
        this.items$ = this._store.select(this._DATA_BROWSER_SELECTORS.selectItems);
        this.filters$ = this._store.select(this._DATA_BROWSER_SELECTORS.selectFilters);
        this.pageInfo$ = this._store.select(this._DATA_BROWSER_SELECTORS.selectPageInfo);

        try {
            this.filteredViewList$.next(
                (JSON.parse(this._localStorageService.getItem(this.featureName + 'FilteredViewList')) ?? [] as FilterPreset[]).
                    concat(this.presettedFilteredView)
            )
        } catch (err: any) {
            console.log(err.message)
        }

        this.selectedFilteredview$.pipe(
            filter(value => value !== null),
            tap(filterPreset => this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.setViewFilters({ filters: filterPreset.filterSetup }))),
            takeUntil(this.ngUnsubscribe),
        ).subscribe()

        combineLatest([
            this._store.select(this._DATA_BROWSER_SELECTORS.selectFilters).pipe(
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
            ),
            this._store.select(this._DATA_BROWSER_SELECTORS.selectPageInfo).pipe(
                distinctUntilChanged((prev, curr) => prev.size === curr.size && prev.page === curr.page)
            )
        ]).pipe(
            debounceTime(300),
            switchMap(([filters, pageInfo]) => of(this._store.dispatch(DATA_BROWSER_GENERIC_ACTIONS.getItems({ featureName: this._featureService.getFeatureName(), filters, pageInfo })))),
            takeUntil(this.ngUnsubscribe),
        ).subscribe();
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('data-browser-container');
    }

    searchTextChange(event: string): void {
        this.searchText = event;
        this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.updateFilters({ filters: { searchText: this.searchText } }));
    }

    updateFilters(value: any) {
        if (value.selected) {
            this.selectedFilteredview$.next(null)
            delete value.selected
            this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.updateFilters({ filters: { ...value } }));
        } else {
            delete value.selected
            this.filters$.pipe(
                tap(filters => {
                    if ((Object.keys(filters)).some(el => el.includes(Object.keys(value)[0]))) {
                        this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.removeFilters({ filters: { ...value } }));
                    }
                }),
                take(1)
            ).subscribe()
        }
    }

    togglePanel(type: 'filter' | 'columns'): void {
        type === 'filter' ? this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.toggleFilterPanel())
            : this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.toggleColumnsSelectorPanel());
    }

    updatePaginator(event: PaginatorState) {
        this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.updatePaginator({ paginatorEvent: event }));
    }

    selectFilteredView(event: FilterPreset) {
        this.selectedFilteredview$.next(event)
    }

    removeFilterChip(filterKey: string, filterValue: any) {
        this.selectedFilteredview$.next(null)
        this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.removeFilters({ filters: { [filterKey]: null } }));
    }

    deleteAllFilters() {
        this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.setViewFilters({ filters: {} }));
        this.selectedFilteredview$.next(null)
    }

    handleFilteredViewSubmit(savePreset: OverlayPanel) {
        this.filters$.pipe(
            take(1),
            tap(filters => {
                const newFilteredView: FilterPreset = {
                    name: this.saveFilteredView.controls.filterViewName.value,
                    filterSetup: filters
                };
                const filteredViewList: FilterPreset[] = JSON.parse(this._localStorageService.getItem(this.featureName + 'FilteredViewList')) ?? []
                this._localStorageService.setItem(this.featureName + 'FilteredViewList', JSON.stringify(filteredViewList.concat(newFilteredView)))
                this.filteredViewList$.next(this.filteredViewList$.getValue().concat(newFilteredView))
                this.selectedFilteredview$.next(newFilteredView)

            })
        ).subscribe()
        savePreset.hide()
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this._store.dispatch(this._DATA_BROWSER_PAGE_ACTIONS.destroyDataBrowser());
    }

    tableExport(selectedItems: any[]) {
        this._columnsSelectorService.selectedColumns$.pipe(
            take(1),
            tap(selectedColumns => {
                const date = new Date()
                switch (this.exportExtension) {
                    case 'json':
                        saveAs(
                            new File([JSON.stringify(selectedItems, null, 2)],
                                `exported ${this.titleLabel} ${date.toLocaleString().replaceAll('/', '').replace(',', '').replace(' ', '_').replaceAll(':', '')}.json`)
                        )
                        break;
                    case 'csv':
                        const csvFileContent: string = this._exportService.csvExportString(selectedColumns, selectedItems)
                        saveAs(
                            new File([csvFileContent],
                                `exported ${this.titleLabel} ${date.toLocaleString().replaceAll('/', '').replace(',', '').replace(' ', '_').replaceAll(':', '')}.csv`))
                        break;
                    default:
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Extention not implemented' });
                }
                this.exportPanel.hide()
            })
        ).subscribe()
    }

    redirectToCreatePage() {
        this._router.navigate([this._router.url, 'create']);
    }
}