import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from "@angular/core";


@Component({
    selector: 'detail-page-wrapper',
    templateUrl: `./detail-page-wrapper.component.html`,
    styleUrls: ['./detail-page-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DetailPageWrapperComponent {

    @Input() hasBottomBar: boolean = true;


    constructor(private _elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add(
            'detail-page-wrapper',
            (this.hasBottomBar ? 'detail-page-wrapper--bottom-bar' : 'detail-page-wrapper--no-bottom-bar')
        );
    }
}