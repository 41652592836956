<ng-container *ngIf="{
    items: items$ | async,
    pageInfo: pageInfo$ | async,
    isLoading: isLoading$ | async,
    isColumnsSelecotorVisible: isColumnsSelecotorVisible$ | async,
    isFilterVisible: isFilterVisible | async,
    selectedItems: selectedItems$ | async,
    selectedFilteredview: selectedFilteredview$ | async,
    filters: filters$ | async,
    filteredViewList: filteredViewList$ | async,
} as vm">



    <p-toast></p-toast>
    <p-overlayPanel #exportPanel styleClass="export-panel-container">
        <ng-template pTemplate="content">
            <div class="d-flex center">
                <div class="d-flex between">
                    <div class="align-middle sm-padding">
                        <i class="pi pi-download"></i>
                    </div>
                    <div class="align-middle sm-padding">
                        {{dataBrowserDictionary.export_panel.header | translate}}
                    </div>
                    <div class="align-middle sm-padding">
                        <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-text"
                            (click)="exportPanel.hide()"></p-button>
                    </div>
                </div>
                <div class="d-flex center">
                    <p>{{dataBrowserDictionary.export_panel.items_count | translate:
                        {count:vm.selectedItems.length} }}</p>
                </div>
                <div class="d-flex center">
                    <p-selectButton [options]="exportExtensionOptions" [(ngModel)]="exportExtension" optionLabel="label"
                        optionValue="value"></p-selectButton>
                </div>
                <div class="d-flex center">
                    <p>{{dataBrowserDictionary.export_panel.body | translate}}</p>
                </div>
                <div>
                    <p-button styleClass="p-button-outlined" (click)="tableExport(vm.selectedItems)"
                        [label]="dataBrowserDictionary.export_panel.button | translate"></p-button>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
    <p-overlayPanel #savePreset styleClass="save-preset-container">
        <ng-template pTemplate="content">
            <form [formGroup]="saveFilteredView" class="d-flex center">
                <div class="d-flex between">
                    <div class="align-middle sm-padding">
                        <i class="pi pi-download"></i>
                    </div>
                    <div class="align-middle sm-padding">
                        {{dataBrowserDictionary.save_preset_panel.header | translate}}
                    </div>
                    <div class="align-middle sm-padding">
                        <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-text"
                            (click)="savePreset.hide()"></p-button>
                    </div>
                </div>
                <div class="d-flex center">
                    <p>{{dataBrowserDictionary.save_preset_panel.body | translate}}</p>
                </div>
                <div class="d-flex center">
                    <input type="text" pInputText formControlName="filterViewName" />
                </div>
                <div>
                    <p-button type="submit" (click)="handleFilteredViewSubmit(savePreset)"
                        styleClass="p-button-outlined"
                        [label]="dataBrowserDictionary.save_preset_panel.button_text | translate"></p-button>
                </div>
            </form>
        </ng-template>
    </p-overlayPanel>


    <div class="data-browser-bar between primary-bg-color">
        <div class="p-2">
            <span class="font-semibold text-2xl">{{titleLabel | translate}} </span>
            <span class="text-xl">{{ vm.selectedFilteredview | resolveFilteredviewTextPipe }}</span>
        </div>
        <div *ngIf="!mainSelectorFilter"></div>
        <div class="action-button-wrapper">
            <speed-dial-button *ngIf="speedDialItems" [speedDialItems]="speedDialItems"
                [exportPanel]="exportPanel"></speed-dial-button>
            <p-button *ngIf="newItemLabel" type="button" class="mr-2" [label]="newItemLabel | translate"
                icon="pi pi-plus" (onClick)="redirectToCreatePage()"></p-button>
        </div>
    </div>
    <div class="data-browser-bar between secondary-bg-color">
        <div class="pl-3">
            <multi-selector-filter *ngIf="mainSelectorFilter" [filterLabel]="mainSelectorFilter.filterLabel"
                [filterKey]="mainSelectorFilter.filterField" [options]="mainSelectorFilter.options"
                (filterChange)="updateFilters($event)"></multi-selector-filter>
        </div>
        <div>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText [ngModel]="searchText" (ngModelChange)="searchTextChange($event)" />
            </span>
            <p-button type="button" class="ml-2 mr-2" styleClass="p-button-outlined" (click)="togglePanel('columns')"
                icon="pi pi-sliders-h"></p-button>
            <p-button type="button" class="mr-2" styleClass="p-button-rounded" (click)="togglePanel('filter')"
                icon="pi pi-filter"></p-button>
        </div>
    </div>
    <div *ngIf="(vm.filters | objectKeysPipe).length > 0" class="data-browser-bar between secondary-bg-color">
        <div class="flex flex-row max-h-full overflow-x-auto">
            <ng-container *ngIf="(vm.filters | objectKeysPipe).length > 0">
                <div class="min-w-max p-1 flex align-items-center">
                    {{dataBrowserDictionary.table_header.filters_chips_text | translate}}</div>
                <div class="flex flex-row overflow-x-auto overflow-y-clip">
                    <p-chip class="m-1 w-max white-space-nowrap" *ngFor="let filterKey of vm.filters | objectKeysPipe"
                        [label]="vm.filters | filterChipTextPipe:{key:filterKey, feature:featureName}"
                        [removable]="true" (onRemove)="removeFilterChip(filterKey, vm.filters[filterKey])"></p-chip>
                </div>
            </ng-container>
        </div>
        <div class="min-w-max flex-shrink-0">
            <p-button type="button" class="ml-2 mr-2" [disabled]="vm.selectedFilteredview ? true : false"
                (click)="savePreset.toggle($event)" icon="pi pi-save"
                [label]="dataBrowserDictionary.table_header.save_filter_view_button | translate"></p-button>
            <p-button type="button" class="mr-2" styleClass="p-button-outlined" (click)="deleteAllFilters()"
                icon="pi pi-trash"
                [label]="dataBrowserDictionary.table_header.delete_all_filters_button | translate"></p-button>
        </div>
    </div>
    <div class="data-browser-content">
        <table-wrapper *ngIf="!vm.isLoading" [columns]="columns" [items]="vm.items" [pageInfo]="vm.pageInfo"
            [rowItemTemplate]="rowItemTemplate" (pageInfoChange)="updatePaginator($event)">
        </table-wrapper>
        <div class="spinner-container" *ngIf="vm.isLoading">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <p-sidebar [(visible)]="vm.isFilterVisible" position="left" styleClass="w-30rem"
            (onHide)="togglePanel('filter')">
            <h3>{{dataBrowserDictionary.filters_panel.side_bar_title | translate:{feature:featureName} }}</h3>
            <div *ngIf="vm.filteredViewList" class="p-3">
                <div class="pl-3 text-xl font-bold">Select a list view</div>
                <div class="flex justify-content-center">
                    <p-dropdown [options]="vm.filteredViewList" [ngModel]="vm.selectedFilteredview"
                        (ngModelChange)="selectFilteredView($event)" optionLabel="name"
                        placeholder="Select a view"></p-dropdown>
                </div>
            </div>
            <ng-container *ngFor="let filter of filtersConfig">
                <ng-container [ngSwitch]="filter.type">
                    <calendar-filter *ngSwitchCase="FilterTypeEnum.CALENDAR" [filterLabel]="filter.filterLabel"
                        [filterKey]="filter.filterField" [calendarConfig]="filter.calendarConfig"
                        (filterChange)="updateFilters($event)"></calendar-filter>
                    <slider-filter *ngSwitchCase="FilterTypeEnum.SLIDER" [filterLabel]="filter.filterLabel"
                        [isRange]="false" [filterKey]="filter.filterField" [sliderConfig]="filter.sliderConfig"
                        (filterChange)="updateFilters($event)"></slider-filter>
                    <slider-filter *ngSwitchCase="FilterTypeEnum.SLIDER_RANGE" [filterLabel]="filter.filterLabel"
                        [isRange]="true" [filterKey]="filter.filterField" [sliderConfig]="filter.sliderConfig"
                        (filterChange)="updateFilters($event)"></slider-filter>
                    <single-choice-filter *ngSwitchCase="FilterTypeEnum.SINGLE" [filterLabel]="filter.filterLabel"
                        [filterKey]="filter.filterField" [options]="filter.options"
                        (filterChange)="updateFilters($event)"></single-choice-filter>
                    <multiple-choice-filter *ngSwitchCase="FilterTypeEnum.MULTIPLE" [filterLabel]="filter.filterLabel"
                        [filterKey]="filter.filterField" [options]="filter.options"
                        (filterChange)="updateFilters($event)"></multiple-choice-filter>
                    <selection-filter *ngSwitchCase="FilterTypeEnum.SINGLE_SELECTION" [filterLabel]="filter.filterLabel"
                        [isMultipleSelection]="false" [filterKey]="filter.filterField" [options]="filter.options"
                        [endpointConfig]="filter.endpointConfig"
                        (filterChange)="updateFilters($event)"></selection-filter>
                    <selection-filter *ngSwitchCase="FilterTypeEnum.MULTIPLE_SELECTION"
                        [filterLabel]="filter.filterLabel" [isMultipleSelection]="true" [filterKey]="filter.filterField"
                        [options]="filter.options" [endpointConfig]="filter.endpointConfig"
                        (filterChange)="updateFilters($event)"></selection-filter>
                </ng-container>
            </ng-container>
        </p-sidebar>
        <p-sidebar [(visible)]="vm.isColumnsSelecotorVisible" position="left" (onHide)="togglePanel('columns')">
            <h3>{{dataBrowserDictionary.columns_panel.side_bar_title | translate}}</h3>
            <columns-selector [columns]="columns"></columns-selector>
        </p-sidebar>
    </div>
</ng-container>