import IUser from "-albicchiere-types/lib/base/user";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'UserNameOrEmailPipe'
})
export class UserNameOrEmailPipe implements PipeTransform {
    transform(value: IUser, ...args: any[]) {
        switch (true) {
            case (!Boolean(value)):
                return `Undefined`
            case (Boolean(value.name) && Boolean(value.lastName)):
                return `${value.name} ${value.lastName}`
            case (Boolean(value.email)):
                return `${value.email}`
            default:
                return `${value._id}`
        }
    }
}