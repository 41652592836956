
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forEach } from 'lodash';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, filter, takeUntil, withLatestFrom } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { DASHBOARD_DICTIONARY } from 'src/translations/dictionaries/dashboard.dictionary';
import { DestroyableSubscription } from 'src/utils/destroyable-subscription';

@Component({
    selector: 'dashboard-component',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent extends DestroyableSubscription implements OnInit, AfterViewInit {

    dashboardDictionary = DASHBOARD_DICTIONARY;

    public tabsItems$ = new BehaviorSubject<MenuItem[]>([
        {
            label: this._translate.instant(this.dashboardDictionary.tabs.vintages),
            items: [
                {
                    label: this._translate.instant(this.dashboardDictionary.tabs.wines),
                    routerLink: '/dashboard/wines/wines'
                },
                {
                    label: this._translate.instant(this.dashboardDictionary.tabs.vintages),
                    routerLink: '/dashboard/wines/vintages'
                }
            ]
        }, {
            label: this._translate.instant(this.dashboardDictionary.tabs.companies),
            items: [
                {
                    label: 'Companies',
                    routerLink: '/dashboard/companies'
                },
                {
                    label: 'Document Pendings',
                    routerLink: '/dashboard/document-pending'
                }
            ]
        }, {
            label: this._translate.instant(this.dashboardDictionary.tabs.users),
            routerLink: '/dashboard/users',
        }, {
            label: this._translate.instant(this.dashboardDictionary.tabs.dispensers),
            routerLink: '/dashboard/dispensers',
        }, {
            label: this._translate.instant(this.dashboardDictionary.tabs.tags),
            items: [
                {
                    label: this._translate.instant(this.dashboardDictionary.tabs.smartbags),
                    routerLink: '/dashboard/tags/smartbags'
                },
                {
                    label: this._translate.instant(this.dashboardDictionary.tabs.bottles),
                    routerLink: '/dashboard/tags/bottles'
                }
            ]
        }, {
            label: 'QR Code',
            routerLink: '/dashboard/qrcode',
        },
    ]);

    constructor(private _router: Router, private _elementRef: ElementRef, private _translate: TranslateService, private _authService: AuthService) {
        super()
    }

    ngOnInit(): void {
        if (this._router.routerState.snapshot.url === '/dashboard') {
            this._router.navigate(['/dashboard/wines/vintages']);
        }
        this.changedUrlEvent(this._router.routerState.snapshot.url, this.tabsItems$.value)
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                withLatestFrom(this.tabsItems$),
                takeUntil(this.ngUnsubscribe)
            ).subscribe(
                ([routerEvent, tabsItem]) => this.changedUrlEvent((routerEvent as NavigationEnd).urlAfterRedirects, tabsItem)
            );
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('dashboard-container');
    }

    changedUrlEvent(url: string, tabsItems: MenuItem[]) {
        const activeIndex = [-1, -1];
        forEach(tabsItems, (tabItem, idx) => {
            if (tabItem.routerLink === undefined) {
                tabItem.items.forEach((subTabItem, subIdx) => {
                    if (url.includes(subTabItem.routerLink)) {
                        activeIndex[0] = idx;
                        activeIndex[1] = subIdx;
                    }
                });
            } else {
                if (url.includes(tabItem.routerLink)) {
                    activeIndex[0] = idx;
                }
            }
        });
        if (activeIndex[0] !== -1) {
            if (activeIndex[1] !== -1) {
                tabsItems[activeIndex[0]].label = tabsItems[activeIndex[0]].items[activeIndex[1]].label;
            }
            tabsItems[activeIndex[0]].styleClass = 'selected-menu-item';
            this.tabsItems$.next(tabsItems.map((el, index) => {
                if (index !== activeIndex[0]) {
                    el.styleClass = 'unselected-menu-item';
                }; return el
            }));
        }
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public logout() {
        this._authService.logout();
    }
}