export const SHARED_TRANSLATION_EN = {
    shared: {
        boolean: {
            true: 'True',
            false: 'False',
            yes: 'Yes',
            no: 'No',
        },
        status: {
            all: 'All',
            pending: 'Pending',
            active: 'Active',
            inactive: 'Inactive'
        },
        speed_dial: {
            export: 'Export',
            deduplicate: 'Deduplicate',
            delete: 'Delete',
        },
        network_status: {
            online: 'Online',
            offline: 'Offline',
        },
        records: {
            updated_by_label: 'Last update by {{updatedBy}} on {{updatedAt}}',
            created_by_label: 'Created by {{createdBy}} on {{createdAt}}',
        }
    }
}