export const DASHBOARD_TRANSLATIONS_IT = {
    dashboard: {
        tabs: {
            wines: 'Vini',
            companies: 'Aziende',
            users: 'Utenti',
            //dispensers: 'Dispensers',
            //smartbags: 'Smartbags',
        },
        avatar_text: 'benvenuto'
    }
}