import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DataBrowserEffects } from '@dashboard-components/data-browser/store/data-browser.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HomeComponent } from 'src/home/home.component';
import { AuthGuard } from 'src/login/guards/auth.guard';
import { LoginEffects } from 'src/login/store/login.effects';
import { loginReducer } from 'src/login/store/login.reducers';
import { AuthService } from 'src/services/auth.service';
import { BackendService } from 'src/services/backend.service';
import { DECLARATIONS, ROUTES } from './app.routing';
import { SharedModule } from './shared.module';
// import ngx-translate and the http loader
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalStorageService } from 'src/services/local-storage.service';
import { AlbiTranslateLoader } from './albiTranslateLoader';

@NgModule({
    declarations: [
        ...DECLARATIONS
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        // ngx-translate and the loader module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: AlbiTranslateLoader
            }
        }),
        FormsModule,
        RouterModule.forRoot(ROUTES),
        StoreModule.forRoot({ login: loginReducer }),
        EffectsModule.forRoot([LoginEffects, DataBrowserEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: true, // Restrict extension to log-only mode
            connectInZone: true
        }),
        HttpClientModule,
        SharedModule
    ],
    providers: [
        AuthService,
        AuthGuard,
        BackendService,
        LocalStorageService,
    ],
    bootstrap: [HomeComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}