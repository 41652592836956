import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'detail-bottom-bar',
    templateUrl: `./detail-bottom-bar.component.html`,
    styleUrls: ['./detail-bottom-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DetailBottomBarComponent {

    @Input() updatedByText: string;

    constructor(private _elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('detail-bottom-bar');
    }

}