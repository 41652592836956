import { COMPANY_TRANSLATIONS_IT } from "./company.translations";
import { COMPONENTS_TRANSLATIONS_IT } from "./components.translations";
import { DASHBOARD_TRANSLATIONS_IT } from "./dashboard.translations";
import { VINTAGE_TRANSLATIONS_IT } from "./vintage.translations";
import { WINE_TRANSLATIONS_IT } from "./wine.translations";


export const IT_TRANSLATIONS = {
    ...COMPONENTS_TRANSLATIONS_IT,
    ...WINE_TRANSLATIONS_IT,
    ...VINTAGE_TRANSLATIONS_IT,
    ...DASHBOARD_TRANSLATIONS_IT,
    ...COMPANY_TRANSLATIONS_IT,
}