import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { FilterConfig, FilterTypeEnum } from "@dashboard-components/data-browser/models/filters.model";
import { PageInfo } from "@dashboard-components/data-browser/store/data-browser.state";
import { TableColumn } from "@dashboard-components/table/table.component";
import { MessageService } from "primeng/api";
import { PaginatorState } from "primeng/paginator";
import { BehaviorSubject, Observable, catchError, combineLatest, defer, distinctUntilChanged, filter, forkJoin, iif, map, of, switchMap, take, takeUntil, tap } from "rxjs";
import { BackendService } from "src/services/backend.service";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";
type NestedConfig = {
    endpoint: (id: string) => string,
    responseField: string,
    nestedField: string,
    nestedFieldId: string,
    mapToTableObj: (nestedResult: any) => any;
}
export type SectionDataExplorerEndpointConfig = {
    endpointUrl: string,
    responseField: string,
    nestedConfig?: NestedConfig,
    secondaryNestedConfig?: NestedConfig,
};

@Component({
    selector: 'section-data-explorer',
    templateUrl: `./section-data-explorer.component.html`,
    styleUrls: ['./section-data-explorer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService],
})
export class SectionDataExplorerComponent extends DestroyableSubscription implements AfterViewInit, OnInit {

    @Input() tableTitle: string;
    @Input() endpointConfig: SectionDataExplorerEndpointConfig;
    @Input() rowItemTemplate: TemplateRef<any>;
    @Input() tableColumns: TableColumn[];
    @Input() filtersConfig: FilterConfig[];
    @Input() detailPagePath: string;

    FilterTypeEnum = FilterTypeEnum;

    public isLoading$ = new BehaviorSubject(false);

    public filters$ = new BehaviorSubject<any>({});

    public searchText$ = new BehaviorSubject<string>(null);

    public pageInfo$ = new BehaviorSubject<PageInfo>({ size: 0, pages: 0, page: 1 });
    public isFilterVisible$ = new BehaviorSubject(false);
    public isSearchInputVisible$ = new BehaviorSubject(false);
    public isDeatilButtonActive$ = new BehaviorSubject(false);

    public items$: Observable<any>;

    constructor(private _elementRef: ElementRef, private _backendService: BackendService, private _router: Router) {
        super();
        this.isLoading$.next(true);
    }

    ngOnInit(): void {
        this.items$ = combineLatest([
            this.pageInfo$.pipe(
                distinctUntilChanged((prev, curr) => prev.page === curr.page)
            ),
            this.filters$.pipe(
                distinctUntilChanged((prev, curr) => {
                    return JSON.stringify(prev) === JSON.stringify(curr)
                })
            ),
            of(this.endpointConfig)
        ]).pipe(
            tap(_ => this.isLoading$.next(true)),
            switchMap(([pageInfo, filters, endpointConfig]) =>
                iif(
                    () => endpointConfig.nestedConfig !== undefined,
                    defer(() =>
                        this._backendService.get<{ [responseField: string]: any[], page: any, pages: any }>(this.resolveEndpoint(pageInfo.page, filters)).pipe(
                            filter(response => response !== null || response !== undefined),
                            tap(response => {
                                this.pageInfo$.next({ size: 10, pages: response.pages as number, page: response.page })
                            }),
                            map(response => response[this.endpointConfig.responseField]
                                // remove this line after backend fix
                                .filter(elm => elm[this.endpointConfig.nestedConfig.nestedFieldId] !== null && elm[this.endpointConfig.nestedConfig.nestedFieldId] !== undefined)),
                            map(items => ({
                                items,
                                nestedCalls: items.map(elm =>
                                    iif(() => this.endpointConfig.secondaryNestedConfig !== undefined,
                                        defer(() => this._backendService.get<any>(this.endpointConfig.nestedConfig.endpoint(elm[this.endpointConfig.nestedConfig.nestedFieldId])).pipe(
                                            map(response => ({
                                                [this.endpointConfig.nestedConfig.nestedField]: this.endpointConfig.nestedConfig.mapToTableObj(response[this.endpointConfig.nestedConfig.responseField])
                                            })),
                                            catchError(_ => {
                                                return this._backendService.get<any>(this.endpointConfig.secondaryNestedConfig.endpoint(elm[this.endpointConfig.secondaryNestedConfig.nestedFieldId])).pipe(
                                                    map(response => ({
                                                        [this.endpointConfig.secondaryNestedConfig.nestedField]: this.endpointConfig.secondaryNestedConfig.mapToTableObj(response[this.endpointConfig.secondaryNestedConfig.responseField])
                                                    }))
                                                );
                                            })
                                        )),
                                        defer(() => this._backendService.get<any>(this.endpointConfig.nestedConfig.endpoint(elm[this.endpointConfig.nestedConfig.nestedFieldId]))).pipe(
                                            map(response => ({
                                                [this.endpointConfig.nestedConfig.nestedField]: this.endpointConfig.nestedConfig.mapToTableObj(response[this.endpointConfig.nestedConfig.responseField])
                                            })))
                                    )
                                )
                            })),
                            switchMap(({ items, nestedCalls }) => forkJoin(nestedCalls).pipe(
                                map(resolvedObj => items.map((elm, idx) => ({ ...elm, ...resolvedObj[idx] }))),
                            ))
                        )
                    ),
                    defer(() => this._backendService.get<{ [responseField: string]: any[], page: any, pages: any }>(this.resolveEndpoint(pageInfo.page, filters)).pipe(
                        filter(response => response !== null || response !== undefined),
                        tap(response => {
                            this.pageInfo$.next({ size: 10, pages: response.pages as number, page: response.page })
                        }),
                        map(response => response[this.endpointConfig.responseField]),
                    ))
                )
            ),
            tap(_ => this.isLoading$.next(false)),
        )

        this.searchText$.pipe(
            filter(value => value !== null),
            tap(value => {
                if (value === undefined) {
                    const newFilterValue = this.filters$.value;
                    delete newFilterValue.search;
                    this.filters$.next({ ...newFilterValue });
                } else if (value === '') {
                    const newFilterValue = this.filters$.value;
                    delete newFilterValue.search;
                    this.filters$.next({ ...newFilterValue });
                } else {
                    this.filters$.next({ ...this.filters$.value, search: value })
                }
            }),
            takeUntil(this.ngUnsubscribe),
        ).subscribe();

        this.isDeatilButtonActive$.next(!Boolean(this.detailPagePath));
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('section-data-explorer')
    }

    updatePaginator(event: PaginatorState) {
        this.pageInfo$.next({ page: (event.page + 1), size: event.rows, pages: event.pageCount })
    }

    toggleFilterPanel() {
        this.isFilterVisible$.next(!this.isFilterVisible$.value);
    }

    toggleSearchInput() {
        this.isSearchInputVisible$.next(!this.isSearchInputVisible$.value);
    }

    updateSearchText(event: string) {
        this.searchText$.next(event);
    }

    updateFilters(value: any) {
        if (value.selected) {
            delete value.selected;
            this.filters$.pipe(
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
                tap(currFilters => console.log(currFilters)),
                tap(currFilters => this.filters$.next({ ...currFilters, ...value })),
                take(1),
            ).subscribe();
        } else {
            this.filters$.pipe(
                tap(currFilters => {
                    const removeFilterKey = Object.keys(value)[0];
                    if ((Object.keys(currFilters)).some(el => el.includes(removeFilterKey))) {
                        delete currFilters[removeFilterKey];
                        this.filters$.next(currFilters);
                    }
                }),
                take(1)
            ).subscribe();
        }
    }

    handleDetailPageButton(id: string) {
        this._router.navigate([this.detailPagePath, id]);
    }

    private resolveEndpoint(page: number, filters: any): string {
        let divider: string = '';

        if (!this.endpointConfig.endpointUrl.includes('?')) {
            divider = '?';
        }

        const filtersKey = Object.keys(filters);
        const stringFilters = [];
        for (const key of filtersKey) {
            stringFilters.push(`${key}=${Array.isArray(filters[key]) ? filters[key].join(',') : filters[key]}`);
        }
        return `${this.endpointConfig.endpointUrl}${divider}${stringFilters.join('&')}&limit=10&page=${page}`
    }
}