export const DISPENSER_TRANSLATE_EN = {
    dispenser: {
        field_label: {
            id: 'Id',
            alert: 'Alert',
            model: 'Model',
            name: 'Name',
            serial: 'Serial',
            firmware: 'Firmware',
            status: 'Status',
            mode: 'Mode',
            battery_status: 'Battery status',
            owner_id: 'Owner id',
            paired_at: 'Paired at',
            bag_id: 'Bag id',
            thing_name: 'Thing name',
            percentage: 'Percentage',
            paired_by: 'Paired by',
        },
        field_values: {
            model: {
                "home-m+": 'Albi Home-M+',
                "home-m": 'Albi Home-M',
                pro: 'Albi Pro',
            },
            firmware: {
                "1-0-0-00": '1.0.0.00',
                "1-0-1-00": '1.0.1.00',
                "1-1-0-00": '1.1.0.00'
            },
            alert: {
                pouring: 'Pouring',
                pairing: 'Pairing',
                temperature: 'Temperature',
                scan_tag: 'Scan tag',
            },
        },
        list: {
            table_title_label: 'Dispensers',
            table_new_item: 'New Dispenser',
        },
        detail: {
            header: {
                bag_inside: 'Bag Inside',
            },
            tabs: {
                properties: 'Properties',
                logs: 'Logs',
                performances: 'Performances',
            },
            properties: {
                about: {
                    title: 'About',
                    info: {
                        title: 'Info',
                        dispenser_name: 'Dispenser name',
                        mac_address: 'Mac address',
                        id: 'Id',
                        warranty: 'Warranty',
                        serial_number: 'Serial number',
                    },
                    specs: {
                        title: 'Specs',
                        model: 'Model',
                        cooling_mode: 'Cooling mode',
                        firmware: 'Firmware',
                        last_firmware_update: 'Last firmware update',
                    },
                    gear: {
                        title: 'Gear',
                        battery_status: 'Battery status'
                    },
                },
                save_button: 'Save',
            }
        }
    }
}