
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from "@ngx-translate/core";
import { filter, map, take, tap } from 'rxjs';
import { LOGIN_PAGE_ACTIONS } from 'src/login/store/login.actions';
import { LoginState } from 'src/login/store/login.state';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'home-component',
    templateUrl: `./home.component.html`,
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements AfterViewInit {

    constructor(
        private _store: Store<LoginState>,
        private _authService: AuthService,
        private _router: Router,
        private _translate: TranslateService,
        private _elementRef: ElementRef
    ) {
        this._translate.setDefaultLang('en');
        this._translate.use('en');
        this._router.events.pipe(
            filter(ev => ev instanceof NavigationStart),
            map(ev => ev as NavigationStart),
            tap((ev: NavigationStart) => this._authService.setRequestedUrl(ev.url)),
            take(1),
        ).subscribe();
        this._store.dispatch(LOGIN_PAGE_ACTIONS.initLoginStateFromStorage());
    }
    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('light-mode');
    }
}