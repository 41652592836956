import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DATA_BROWSER_SELECTORS, DATA_BROWSER_SELECTORS_TYPE } from "@dashboard-components/data-browser/store/data-browser.selectors";
import { Store } from "@ngrx/store";
import { BehaviorSubject, distinctUntilChanged, filter, map, takeUntil, tap } from "rxjs";
import { FeatureService } from "src/services/feature.service";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";


export type SliderFilterConfigType = {
    min: number;
    max: number;
    step?: number;
};

@Component({
    selector: 'slider-filter',
    templateUrl: `./slider-filter.component.html`,
    styleUrls: ['./slider-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SliderFilterComponent extends DestroyableSubscription implements OnInit {

    @Input() filterLabel: string;
    @Input() filterKey: string;

    @Input() isRange: boolean = false;

    @Input() sliderConfig: SliderFilterConfigType;

    @Output() filterChange = new EventEmitter();

    public sliderValue = new BehaviorSubject<number[]>([])

    filterForm: FormGroup;
    private _DATA_BROWSER_SELECTORS: DATA_BROWSER_SELECTORS_TYPE;

    constructor(private _store: Store, private _featureService: FeatureService) {
        super();
        this._DATA_BROWSER_SELECTORS = DATA_BROWSER_SELECTORS(this._featureService.getFeatureName());
    }


    ngOnInit(): void {
        this.filterForm = new FormGroup({
            [this.filterKey + 'Selected']: new FormControl(false),
            [this.filterKey]: new FormControl(this.isRange ? [this.sliderConfig.min ? this.sliderConfig.min : 0, this.sliderConfig.max ? this.sliderConfig.max : 100] : [this.sliderConfig.min ? this.sliderConfig.min : 0])
        });

        if (this.isRange) {
            this.sliderValue.next([this.sliderConfig.min ? this.sliderConfig.min : 0, this.sliderConfig.max ? this.sliderConfig.max : 100])
        } else {
            this.sliderValue.next([this.sliderConfig.min ? this.sliderConfig.min : 0])
        }

        if (!this.sliderConfig) {
            this.sliderConfig = {
                min: 0,
                max: 100,
                step: 0.1,
            };
        } else if (!this.sliderConfig.step) {
            this.sliderConfig.step = 0.1;
        }

        this._store.select(this._DATA_BROWSER_SELECTORS.selectFilters).pipe(
            tap(filters => {
                if (Object.keys(filters).includes(this.filterKey) && !this.filterForm.controls[this.filterKey + 'Selected'].getRawValue()) {
                    //filters include the filter key but the filter key in the side panel is not selected
                    this.filterForm.controls[this.filterKey + 'Selected'].setValue(true)
                    this.filterForm.controls[this.filterKey].setValue(filters[this.filterKey])
                    this.sliderValue.next(filters[this.filterKey])
                } else if (!Object.keys(filters).includes(this.filterKey) && this.filterForm.controls[this.filterKey + 'Selected'].getRawValue()) {
                    //filters don't include the filter key but the filter key in the side panel is selected
                    this.filterForm.controls[this.filterKey + 'Selected'].setValue(false)
                }
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe()

        this.filterForm.valueChanges.pipe(
            filter(formValue => formValue[this.filterKey] != undefined),
            distinctUntilChanged(),
            map(formValue => ({ [this.filterKey]: formValue[this.filterKey], selected: formValue[this.filterKey + 'Selected'] })),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(filter => this.filterChange.emit(filter));
    }

    changeSliderValue(val: any) {
        this.sliderValue.next(val)
    }
}