<form [formGroup]="filterForm">
    <p-panel>
        <ng-template pTemplate="header">
            <p-checkbox [formControlName]="filterKey+'Selected'" [binary]="true" inputId="binary"></p-checkbox>
            <span class="font-semibold">{{filterLabel}}</span>
        </ng-template>
        <div>
            <p-autoComplete [formControlName]="filterKey" [suggestions]="suggestedOptions$ | async"
                [multiple]="isMultipleSelection" (completeMethod)="filterOption($event)" field="name">
                <ng-template let-item pTemplate="item">
                    <div>
                        <div>{{ item.key }}</div>
                        <div>{{ item.name }}</div>
                    </div>
                </ng-template></p-autoComplete>

        </div>
    </p-panel>
</form>