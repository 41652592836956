import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterChoiceOptionType } from "@dashboard-components/data-browser/models/filters.model";
import { DATA_BROWSER_SELECTORS, DATA_BROWSER_SELECTORS_TYPE } from "@dashboard-components/data-browser/store/data-browser.selectors";
import { Store } from "@ngrx/store";
import { map, takeUntil, tap } from "rxjs";
import { FeatureService } from "src/services/feature.service";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";

@Component({
    selector: 'multi-selector-filter',
    templateUrl: `./multi-selector-filter.component.html`,
    styleUrls: ['./multi-selector-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MultiSelectorFilter extends DestroyableSubscription implements OnInit {

    @Input() filterLabel: string;
    @Input() filterKey: string;
    @Input() options: FilterChoiceOptionType[];

    @Output() filterChange = new EventEmitter();

    public selectorForm: FormGroup;
    private _DATA_BROWSER_SELECTORS: DATA_BROWSER_SELECTORS_TYPE;

    constructor(private _store: Store, private _featureService: FeatureService) {
        super();
        this._DATA_BROWSER_SELECTORS = DATA_BROWSER_SELECTORS(this._featureService.getFeatureName());
    }

    ngOnInit(): void {
        this.selectorForm = new FormGroup({
            [this.filterKey]: new FormControl(this.options[0].key)
        });

        this._store.select(this._DATA_BROWSER_SELECTORS.selectFilters).pipe(
            tap(filters => {
                if (Object.keys(filters).includes(this.filterKey) && this.selectorForm.controls[this.filterKey].getRawValue() !== filters[this.filterKey]) {
                    this.selectorForm.controls[this.filterKey].setValue(filters[this.filterKey]);
                }
                else if (filters[this.filterKey] === undefined && this.selectorForm.controls[this.filterKey].getRawValue() !== 'all') {
                    this.selectorForm.controls[this.filterKey].setValue('all');
                }
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe()

        this.selectorForm.valueChanges.pipe(
            map(formValue => ({ [this.filterKey]: formValue[this.filterKey], selected: formValue[this.filterKey] !== 'all' })),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(filter => this.filterChange.emit(filter));
    }
}