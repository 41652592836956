import { CalendarFilterConfigType } from "../panel-filters/calendar-filter/calendar-filter.component";
import { SelectionFilterEndpointConfigType } from "../panel-filters/selection-filter/selection-filter.component";
import { SliderFilterConfigType } from "../panel-filters/slider-filter/slider-filter.component";

export enum FilterTypeEnum {
    MULTIPLE = 'multiple',
    SINGLE = 'single',
    SINGLE_SELECTION = 'single-selection',
    MULTIPLE_SELECTION = 'multiple-selection',
    SLIDER = 'slider',
    SLIDER_RANGE = 'slider-range',
    CALENDAR = 'calendar',
}

export type FilterChoiceOptionType = {
    key: string;
    name: string;
};

export type FilterConfig = {
    type: FilterTypeEnum,
    filterField: string,
    filterLabel: string,
    options?: FilterChoiceOptionType[],
    endpointConfig?: SelectionFilterEndpointConfigType;
    sliderConfig?: SliderFilterConfigType;
    calendarConfig?: CalendarFilterConfigType;
}

export type FilterPreset = {
    name: string,
    filterSetup: { [key: string]: any }
}