import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { City, ICity, State } from 'country-state-city';

@Pipe({
    name: 'resolveCoordinatesPipe'
})
export class ResolveCoordinatesPipe implements PipeTransform {

    constructor(private _translateService: TranslateService) { }

    transform(value: { lat: Number, lng: Number }, ...args: any[]): string {
        const translateCountryName = new Intl.DisplayNames([this._translateService.currentLang], { type: 'region' });

        const cities: (ICity & { distance?: number })[] = City.getAllCities();
        cities.map(city => city.distance = this.haversineDistance(Number(city.latitude), Number(city.longitude), value.lat as number, value.lng as number));
        cities.sort((a, b) => a.distance - b.distance);
        const closestCity = cities[0];
        const state = State.getStateByCodeAndCountry(closestCity.stateCode, closestCity.countryCode);
        const country = translateCountryName.of(closestCity.countryCode);
        return `${closestCity.name} ,${state.name} ,${country}`
    }



    private haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
        // Radius of the Earth in meters
        const R = 6371000.0;

        // Convert degrees to radians
        const lat1Rad = this.toRadians(lat1);
        const lon1Rad = this.toRadians(lon1);
        const lat2Rad = this.toRadians(lat2);
        const lon2Rad = this.toRadians(lon2);

        // Calculate differences in coordinates
        const dlat = lat2Rad - lat1Rad;
        const dlon = lon2Rad - lon1Rad;

        // Haversine formula
        const a = Math.sin(dlat / 2) * Math.sin(dlat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.sin(dlon / 2) * Math.sin(dlon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        // Distance in meters
        const distance = R * c;

        return distance;
    };

    private toRadians(degrees: number) {
        return degrees * (Math.PI / 180);
    };


}