import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'descriptionPipe'
})
export class DescriptionPipe implements PipeTransform {
    transform(value: { locale: string, description: string }[], ...args: any[]) {
        if (value && value.find(el => el.locale === 'en')) {
            return value.find(el => el.locale === 'en').description
        }
        return 'Not Found'
    }
}