export const SMARTBAG_TRANSLATE_EN = {
    smartbag: {
        field_label: {
            id: 'Id',
            serial: 'Serial',
            quality_score: 'Quality score',
            type: 'Type',
            updated_at: 'Updated at',
            owner_id: 'Owner id',
            paired_at: 'Paired at',
            opened_at: 'Opened at',
        },
        list: {
            table_title_label: 'Smartbags',
            table_new_item: 'New Smartbag',
        },
        detail: {
            header: {
                packed_at: 'Packed at'
            }
        }
    }
}