import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable()
export class BackendService {
    private readonly _baseUrl = environment.apiUrl

    constructor(private _http: HttpClient, private _authService: AuthService,) { }

    public get<T>(endpoint: string): Observable<T> {
        /* if (endpoint.includes('wine/') || endpoint.includes('app_settings/') || endpoint.includes('cantina/')) {
             return this._http.get<T>(`http://localhost:4000/${endpoint}`, {
                 headers: {
                     "Authorization": `Bearer ${this._authService.getSessionInfo().token}`
                 }
             });
 
         }*/
        return this._http.get<T>(`${this._baseUrl}/${endpoint}`, {
            headers: {
                "Authorization": `Bearer ${this._authService.getSessionInfo().accessToken}`
            }
        });
    }

    public post<T>(endpoint: string, body: any) {
        return this._http.post<T>(`${this._baseUrl}/${endpoint}`, body, {
            headers: {
                "Authorization": `Bearer ${this._authService.getSessionInfo().accessToken}`
            }
        });

    }
}