<div *ngIf="title" class="section-title" [ngStyle]="customTitleStyle">
    {{title}}
</div>
<div *ngIf="subTitle" class="text-2xl">
    {{subTitle}}
</div>
<div class="section-content-wrapper">
    <div class="section-content">
        <ng-content></ng-content>
    </div>
</div>