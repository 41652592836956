import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Store } from "@ngrx/store";
import { MenuItem, MessageService } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { filter, map, takeUntil, tap } from "rxjs";
import { FeatureService } from "src/services/feature.service";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";
import { DATA_BROWSER_PAGE_ACTIONS } from "../store/data-browser.actions";

export enum SpeedDialActionEnum {
    SYNC = 'sync',
    EXPORT = 'export',
    UPLOAD = 'upload',
    LINK = 'link',
    TRASH = 'trash'
}

export type AlbiMenuItem = MenuItem & { id: SpeedDialActionEnum };

@Component({
    selector: 'speed-dial-button',
    templateUrl: `./speed-dial-button.component.html`,
    styleUrls: ['./speed-dial-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SpeedDialButtonComponent extends DestroyableSubscription implements OnInit, AfterViewInit {

    @Input() speedDialItems?: AlbiMenuItem[];

    @Input() exportPanel?: OverlayPanel;



    constructor(private _messageService: MessageService, private _store: Store,
        private _elementRef: ElementRef, private _featureService: FeatureService) {
        super();
        this._messageService.messageObserver.pipe(
            tap(_ => console.log(_)),
            map((action: any) => this._mapActionIdToAction(action)),
            filter(action => action != null),
            tap(action => this._store.dispatch(action())),
            takeUntil(this.ngUnsubscribe)
        ).subscribe();
    }

    ngOnInit(): void {
        this.speedDialItems?.forEach(elm => {
            elm.command = () => {
                this._messageService.add({ severity: 'success', summary: elm.id });
            }
        });
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('speed-dial-container');
    }

    private _mapActionIdToAction(action: any) {
        switch (action.summary) {
            case 'export':
                this.exportPanel.show(action)
                return DATA_BROWSER_PAGE_ACTIONS(this._featureService.getFeatureName()).speedDialDownload;
            case 'refresh':
                return DATA_BROWSER_PAGE_ACTIONS(this._featureService.getFeatureName()).speedDialRefresh;
            default:
                return null;
        }
    }
}