import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'localeDescriptionPipe'
})
export class LocaleDescriptionPipe implements PipeTransform {

    constructor(private _transalateService: TranslateService) { }

    transform(value: { locale: string, description: string }[], ...args: any[]) {
        const index = value.map(elm => elm.locale).indexOf(this._transalateService.currentLang)
        return (index === -1 || !value[index].description || value[index].description === " ") ? undefined : value[index].description
    }
}