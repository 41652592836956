import { DataBrowserConfig } from "../data-browser.component";

export type PageInfo = { size: number; page: number; pages: number; };

export interface DataBrowserState {
    config: DataBrowserConfig;
    isFilterVisible: boolean;
    isColumnsSelecotorVisible: boolean;
    items: any[];
    pageInfo: PageInfo;
    isLoading: boolean;
    filters: any;
    selectedItems: any[]
}

export const initDataBrowserState = (config?: DataBrowserConfig): DataBrowserState => ({
    isFilterVisible: false,
    isColumnsSelecotorVisible: false,
    items: [],
    pageInfo: { page: 1, size: 10, pages: 1 },
    isLoading: true,
    filters: {},
    config: config,
    selectedItems: []
});

export const DATA_BROWSER_STATE_FEATURE = 'dataBrowserFeature';