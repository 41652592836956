import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';
import { LOGIN_API_ACTIONS, LOGIN_PAGE_ACTIONS } from './login.actions';
@Injectable()
export class LoginEffects {

    constructor(private actions$: Actions, private _authService: AuthService,
        private _router: Router) { }

    loadLoginState = createEffect(() =>
        this.actions$.pipe(
            ofType(LOGIN_PAGE_ACTIONS.initLoginStateFromStorage),
            withLatestFrom(
                of(window.localStorage.getItem('accessToken')),
                of(window.localStorage.getItem('email')),
                of(window.localStorage.getItem('idToken')),
                of(window.localStorage.getItem('refreshToken')),
            ),
            switchMap(([_, token, email, idToken, refreshToken]) =>
                this._authService.checkToken({ accessToken: token, email, idToken, refreshToken }).pipe(
                    map(_ => LOGIN_API_ACTIONS.loginSuccess({
                        accessToken: token,
                        email: email,
                        idToken: idToken,
                        refreshToken: refreshToken
                    })),
                    catchError(_ => of(LOGIN_API_ACTIONS.loginFailure({ error: 'no token' })))
                )
            )));

    handleLogin = createEffect(() =>
        this.actions$.pipe(
            ofType(LOGIN_PAGE_ACTIONS.clickLogin),
            switchMap(({ username, password }) =>
                this._authService.login(username, password).pipe(
                    map(sessionInfo => LOGIN_API_ACTIONS.loginSuccess(sessionInfo)),
                    catchError(error => of(LOGIN_API_ACTIONS.loginFailure({ error })))
                )
            )
        )
    );

    handleLoginSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(LOGIN_API_ACTIONS.loginSuccess),
            tap(({ accessToken, email, idToken, refreshToken }) => {
                this._authService.setSessionInfo({ accessToken, email, idToken, refreshToken });
                this._authService.startRefreshTokenTimer();
            }),
            tap(_ => this._router.navigate([this._authService.getRequestedUrl().includes('login') ? 'dashboard' : this._authService.getRequestedUrl()]))
        ),
        {
            dispatch: false
        }
    );

    handleLoginError = createEffect(() =>
        this.actions$.pipe(
            ofType(LOGIN_API_ACTIONS.loginFailure),
            tap(_ => this._router.navigate(['login']))
        ),
        {
            dispatch: false
        }
    );

}