import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, distinctUntilChanged, filter, map, takeUntil } from "rxjs";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";


export type SliderFilterConfigType = {
    min: number;
    max: number;
    step?: number;
};

@Component({
    selector: 'data-explorer-slider-filter',
    templateUrl: `./data-explorer-slider-filter.component.html`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DataExplorerSliderFilterComponent extends DestroyableSubscription implements OnInit {

    @Input() filterLabel: string;
    @Input() filterKey: string;

    @Input() isRange: boolean = false;

    @Input() sliderConfig: SliderFilterConfigType;

    @Output() filterChange = new EventEmitter();

    public sliderValue = new BehaviorSubject<number[]>([])

    filterForm: FormGroup;

    constructor() {
        super();
    }


    ngOnInit(): void {
        this.filterForm = new FormGroup({
            [this.filterKey + 'Selected']: new FormControl(false),
            [this.filterKey]: new FormControl(this.isRange ? [this.sliderConfig.min ? this.sliderConfig.min : 0, this.sliderConfig.max ? this.sliderConfig.max : 100] : [this.sliderConfig.min ? this.sliderConfig.min : 0])
        });

        if (this.isRange) {
            this.sliderValue.next([this.sliderConfig.min ? this.sliderConfig.min : 0, this.sliderConfig.max ? this.sliderConfig.max : 100])
        } else {
            this.sliderValue.next([this.sliderConfig.min ? this.sliderConfig.min : 0])
        }

        if (!this.sliderConfig) {
            this.sliderConfig = {
                min: 0,
                max: 100,
                step: 0.1,
            };
        } else if (!this.sliderConfig.step) {
            this.sliderConfig.step = 0.1;
        }

        this.filterForm.valueChanges.pipe(
            filter(formValue => formValue[this.filterKey] != undefined),
            distinctUntilChanged(),
            map(formValue => ({ [this.filterKey]: formValue[this.filterKey], selected: formValue[this.filterKey + 'Selected'] })),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(filter => this.filterChange.emit(filter));
    }

    changeSliderValue(val: any) {
        this.sliderValue.next(val)
    }
}