import { BOTTLE_TRANSLATE_EN } from "./bottle.translations";
import { COMPANY_TRANSLATIONS_EN } from "./company.translations";
import { COMPONENTS_TRANSLATIONS_EN } from "./components.translations";
import { DASHBOARD_TRANSLATIONS_EN } from "./dashboard.translations";
import { DISPENSER_TRANSLATE_EN } from "./dispenser.translations";
import { NOT_FOUND_TRANSLATIONS_EN } from "./not-found.translations";
import { SHARED_TRANSLATION_EN } from "./shared.translation";
import { SMARTBAG_TRANSLATE_EN } from "./smartbag.translations";
import { TAG_TRANSLATE_EN } from "./tag.translations";
import { USER_TRANSLATIONS_EN } from "./user.translations";
import { VINTAGE_TRANSLATIONS_EN } from "./vintage.translations";
import { WINE_TRANSLATIONS_EN } from "./wine.translations";


export const EN_TRANSLATIONS = {
    ...COMPONENTS_TRANSLATIONS_EN,
    ...WINE_TRANSLATIONS_EN,
    ...VINTAGE_TRANSLATIONS_EN,
    ...DASHBOARD_TRANSLATIONS_EN,
    ...COMPANY_TRANSLATIONS_EN,
    ...USER_TRANSLATIONS_EN,
    ...DISPENSER_TRANSLATE_EN,
    ...TAG_TRANSLATE_EN,
    ...SMARTBAG_TRANSLATE_EN,
    ...BOTTLE_TRANSLATE_EN,
    ...SHARED_TRANSLATION_EN,
    ...NOT_FOUND_TRANSLATIONS_EN,
}