<form [formGroup]="filterForm">
    <p-panel>
        <ng-template pTemplate="header">
            <p-checkbox [formControlName]="filterKey+'Selected'" [binary]="true" inputId="binary"></p-checkbox>
            <span class="font-semibold">{{filterLabel}}</span>
        </ng-template>
        <ng-container [ngSwitch]="calendarConfig.type">
            <p-calendar *ngSwitchCase="'yearPicker'" [formControlName]="filterKey" view="year" dateFormat="yy"
                [showIcon]="true" [showButtonBar]="true" [maxDate]="calendarConfig.maxDate"
                [minDate]="calendarConfig.minDate"></p-calendar>
            <p-calendar *ngSwitchDefault [formControlName]="filterKey" [showIcon]="true" [showButtonBar]="true"
                [maxDate]="calendarConfig.maxDate" [minDate]="calendarConfig.minDate">
            </p-calendar>
        </ng-container>
    </p-panel>
</form>