import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { filter, map, takeUntil } from "rxjs";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";


export type CalendarFilterConfigType = {
    type?: 'yearPicker',
    maxDate?: Date,
    minDate?: Date
};

@Component({
    selector: 'data-explorer-calendar-filter',
    templateUrl: `./data-explorer-calendar-filter.component.html`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DataExplorerCalendarFilterComponent extends DestroyableSubscription implements OnInit {

    @Input() filterLabel: string;
    @Input() filterKey: string;

    @Input() calendarConfig: CalendarFilterConfigType;

    @Output() filterChange = new EventEmitter();

    filterForm: FormGroup;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.filterForm = new FormGroup({
            [this.filterKey + 'Selected']: new FormControl(false),
            [this.filterKey]: new FormControl<Date | null>(null)
        });

        if (!this.calendarConfig) {
            this.calendarConfig = {
            };
        }

        this.filterForm.valueChanges.pipe(
            filter(formValue => formValue[this.filterKey] != undefined),
            map(elm => ({
                [this.filterKey]:
                    this.calendarConfig.type === 'yearPicker' ? (elm[this.filterKey] as Date).getFullYear() : (elm[this.filterKey] as Date).toISOString(), selected: elm[this.filterKey + 'Selected']
            })),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(filter => this.filterChange.emit(filter));
    }
}