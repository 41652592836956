export interface LoginState {
    token: string | null;
    error: string | null;
    isLoading: boolean;
    initFromStorageDone: boolean;
}

export const initInitialLoginState = (): LoginState => ({
    token: null,
    error: null,
    isLoading: false,
    initFromStorageDone: false
});

export const LOGIN_FEATURE = 'loginFeature';