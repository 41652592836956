export const VINTAGE_TRANSLATIONS_IT = {
    vintage: {
        list: {
            table_title_label: 'Annate',
            table_new_item: 'Crea annata',
            columns: {
                full_name: 'Nome completo',
                id: 'Id',
                wine_id: 'Id vino',
                year: 'Anno',
                year_details: 'Dettagli anno',
                alcohol: 'Alcohol',
                temperature: 'Temperatura di servizio',
                description: 'Descrizione',
                status: 'Stato',
                moods: 'Umore',
                pairings: 'Cibi',
                allergen: 'Allergeni',
                philosophy: 'Filosofia',
                is_claimed_by_winery: 'Reclamato dalla cantina',
                label_search: 'Ricerca etichetta',
                created_at: 'Creato il',
                updated_at: 'Aggiornato il',
                percentage: 'Percentuale'
            },
            main_filters: {
                all: 'Tutti',
                pending: 'In attesa',
                active: 'Attivi',
                inactive: 'Inattivi'
            },
            filters: {
                vintage_year: 'Annata',
                created_by: 'Creato da',
                created_at: 'Creato il',
            },
            speed_dial: {
                export: 'Esporta',
                deduplicate: 'Deduplica',
                delete: 'Elimina',
            }

        },
        detail: {
            created_by_label: 'Creato da {{createdBy}} il {{createdAt}}',
            tabs: {
                properties: 'Proprietà',
                other_vintages: 'Altre annate'
            },
            properties: {
                specs: {
                    title: 'Specifiche',
                },
                wine_properties: {
                    title: 'Proprietà vino',
                    color: 'colore: {{color}}',
                    wine_types: 'tipi: {{wineTypes}}',
                    wine_types_not_found: 'Non sono stati trovati tipi per questo vino',
                    country_region: 'Dal {{country}} in {{region}}',
                    grapes: 'uve:{{grapes}}',
                },
                description: {
                    title: 'Descrizione Annata',
                    paragraph: 'Master - (lingua: {{lang}})',
                },
                pairings: {
                    title: 'Abbinamenti',
                    primary_pairings_label: 'Cibi perfetti',
                    secondary_pairings_label: 'Cibi buoni',
                    moods_label: "Stati d'animo",
                    allergens_label: 'Allergeni',
                    philosophy_label: 'Filosofia',
                },
                /*  awards: {
                      title: 'Awards',
                      awards_label: 'Awards',
                      prices_label: 'Prices',
                  },*/
                required_images: {
                    title: 'Immagini richieste',
                    image_detail: {
                        format: 'Formato: {{format}}',
                        resolution: 'Risoluzione: {{resolution}}',
                    },
                    default_label: {
                        description: "Etichetta principale dell'annata con sfondo trasparente",
                    },
                    bottle_image: {
                        description: "Bottiglia principale dell’annata con sfondo trasparente.",
                    },
                    dispenser_image: {
                        description: "Etichetta dispenser. \nViene generata dal primo caricamento dell’etichetta principale.",
                    },
                    smartbag_image: {
                        description: "Etichetta grafica caricata dalla cantina.",
                        secondary_description: "Appare in: \ndisplay dispenser se disponibile",
                    },
                    chips: {
                        label: 'Etichetta',
                        //tineye: 'Tineye',
                        bottle: 'Bottiglia',
                        //dispenser: 'Dispenser',
                        //smartbag: 'Smartbag',
                    }
                },
                other_images: {
                    title: 'Altre',
                },
                save_panel: {
                    update_string: 'ultimo aggiornamento effettuato da {{updatedBy}} il {{updatedAt}}',
                    submit_button: 'salva',
                },
                status: {
                    active: 'Attivo',
                    inactive: 'Inattivo',
                    pending: 'In attesa',
                }
            },
            other_vintages: {
                table_title_label: 'Altre annate',
                table_new_item: 'Aggiungi annata',
            }
        }
    }
}