export const WINE_TRANSLATIONS_EN = {
    wine: {
        field_label: {
            full_name: 'Full Name',
            name: 'Wine Name',
            id: 'Id',
            color: 'Color',
            winery_id: 'Id Winery',
            country: 'Country',
            region: 'Region',
            status: 'Status',
            grapes: 'Grapes',
            is_claimed_by_winery: 'Is claimed by winery',
            description: 'Description',
            acidity: 'Acidity',
            fizziness: 'Fizziness',
            intensity: 'Intensity',
            sweetness: 'Sweetness',
            tannin: 'Tannin',
            wine_types: 'Wine Types',
            created_at: 'Created at',
            updated_at: 'Updated at',
            created_by: 'Created by',
            updated_by: 'Updated by',
            percentage: 'Percentage',
        },
        field_values: {
            color: {
                red: 'Red',
                rose: 'Rose',
                white: 'White'
            },
            wine_profile: {
                acidity: 'Acidity',
                fizziness: 'Fizziness',
                intensity: 'Intensity',
                tannin: 'Tannin',
                sweetness: 'Sweetness',
            },
            wine_types: {
                sparkling: 'Sparkling',
                sweet: 'Sweet',
                fortified: 'Fortified',
                firm: 'Firm',
            },
        },
        list: {
            table_title_label: 'Wines',
            table_new_item: 'New wine',
        },
        detail: {
            tabs: {
                properties: 'Properties',
                vintages: 'Vintages',
                logs: 'Logs'
            },
            properties: {
                wine_detail: {
                    title: 'Wine Detail',
                    wine_spec: {
                        title: 'Wine Specs',
                        wine_name_label: 'Wine Name',
                        wine_color_label: 'Wine Color',
                        wine_type_label: 'Wine Type',
                        country_label: 'Country',
                        region_label: 'Region',
                        grape_label: 'Grapes',
                    },
                    description: {
                        title: 'Wine description',
                        master_lang_label: 'Master ({{lang}})',
                        not_found_text: 'Description({{lang}}) not found for this wine',
                    },
                },
                save_panel: {
                    submit_button: 'Save',
                },
                ratings: {
                    title: 'Ratings',
                    intensity_text: 'Full body',
                    acidity_text: 'Low acidity',
                    sweetnes_text: 'Bold',
                    tannin_text: 'Tannic',
                    fizziness_text: 'Bubbles',
                },
                wine_profile_not_found: 'Wine Profile Not Found for this wine'
            },
            vintages: {
                table_title_label: 'Wine vintages',
                table_new_item: 'new Wine Vintage',
            }
        },
        main_page: {
            tabs: {
                wines: 'Wines',
                vintages: 'Vintages'
            }
        }
    }
}