<div class="detail-header-container">
    <div class="flex align-items-center pt-3 pb-3">
        <div *ngIf="imageUrl">
            <img [ngClass]="{clipped:IsImageClipped}" [ngStyle]="imageStyle" alt="Header Image" #imgElement
                [src]="imageUrl" onerror="this.src='assets/imgError/ImgError.png'">
        </div>
        <div>
            <ng-container *ngFor="let text of primaryTextArea">
                <div [ngStyle]="text.style">{{text.text}}</div>
            </ng-container>
            <div *ngIf="leftPanelTemplate">
                <ng-container [ngTemplateOutlet]="leftPanelTemplate">
                </ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="rightPanelTemplate">
        <ng-container [ngTemplateOutlet]="rightPanelTemplate">
        </ng-container>
    </div>
</div>