export const COMPONENTS_TRANSLATIONS_IT = {
    components: {
        data_browser: {
            export_panel: {
                header: 'Esporta dati',
                items_count: 'Stai esportando: {{count}} elementi',
                body: 'E’ possibile importare fino a 200 record contemporaneamente attraverso un file csv o json.',
                button: 'Esporta',
            }
        },
        table: {
            detail_button_tooltip: 'Pagina dettaglio'
        }
    }
}