import { ChangeDetectionStrategy, Component, ElementRef, Input, TemplateRef, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'detail-header',
    templateUrl: `./detail-header.component.html`,
    styleUrls: ['./detail-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DetailHeaderComponent {

    @Input() imageUrl: string;
    @Input() imageStyle: object = { 'width': '150px' };
    @Input() IsImageClipped: boolean = false;
    @Input() primaryTextArea: { text: string, style?: object }[];

    @Input() leftPanelTemplate: TemplateRef<any>;
    @Input() rightPanelTemplate: TemplateRef<any>;

    constructor(private _elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('detail-header');
    }
}