
export const USER_DICTIONARY = {
    field_label: {
        name: 'user.field_label.name',
        lastname: 'user.field_label.lastname',
        email: 'user.field_label.email',
        id: 'user.field_label.id',
        gender: 'user.field_label.gender',
        age: 'user.field_label.age',
        nation: 'user.field_label.nation',
        locale: 'user.field_label.locale',
        percentage: 'user.field_label.percentage',
        birthday: 'user.field_label.birthday',
        nationality: 'user.field_label.nationality',
        created_at: 'user.field_label.created_at',
        updated_at: 'user.field_label.updated_at',
        policy_consent: 'user.field_label.policy_consent',
        marketing_consent: 'user.field_label.marketing_consent',
    },
    field_values: {
        gender: {
            male: 'user.field_values.gender.male',
            female: 'user.field_values.gender.female',
            nd: 'user.field_values.gender.nd',
        },
    },
    list: {
        table_title_label: 'user.list.table_title_label',
        table_new_item: 'user.list.table_new_item',
    },
    detail: {
        left_panel: {
            register_label: 'user.detail.left_panel.register_label',
            gps: 'user.detail.left_panel.gps',
        },
        right_panel: {
            active_services: 'user.detail.right_panel.active_services'
        },
        tabs: {
            properties: 'user.detail.tabs.properties',
            consumption: 'user.detail.tabs.consumption',
            orders: 'user.detail.tabs.orders',
            activities: 'user.detail.tabs.activities',
            wine: 'user.detail.tabs.wine',
        },
        properties: {
            personal_info: {
                title: 'user.detail.properties.personal_info.title',
                private_info: {
                    title: 'user.detail.properties.personal_info.private_info.title',
                    name: 'user.detail.properties.personal_info.private_info.name',
                    surname: 'user.detail.properties.personal_info.private_info.surname',
                    email: 'user.detail.properties.personal_info.private_info.email',
                    gender: 'user.detail.properties.personal_info.private_info.gender',
                    birthdate: 'user.detail.properties.personal_info.private_info.birthdate',
                    nationality: 'user.detail.properties.personal_info.private_info.nationality',
                },
                contacts: {
                    title: 'user.detail.properties.personal_info.contacts.title',
                    phone: 'user.detail.properties.personal_info.contacts.phone',
                    cell: 'user.detail.properties.personal_info.contacts.cell',
                },
                account_settings: {
                    title: 'user.detail.properties.personal_info.account_settings.title',
                    language: 'user.detail.properties.personal_info.account_settings.language',
                    measure: 'user.detail.properties.personal_info.account_settings.measure',
                    hour_format: 'user.detail.properties.personal_info.account_settings.hour_format',
                    date_format: 'user.detail.properties.personal_info.account_settings.date_format',
                },
                notification_settings: {
                    title: 'user.detail.properties.personal_info.notification_settings.title',
                    general_push: 'user.detail.properties.personal_info.notification_settings.general_push',
                    general_email: 'user.detail.properties.personal_info.notification_settings.general_email',
                    device_push: 'user.detail.properties.personal_info.notification_settings.device_push',
                    device_email: 'user.detail.properties.personal_info.notification_settings.device_email',
                },
                privacy_settings: {
                    title: 'user.detail.properties.personal_info.privacy_settings.title',
                    basic_auth: 'user.detail.properties.personal_info.privacy_settings.basic_auth',
                    marketing_auth: 'user.detail.properties.personal_info.privacy_settings.marketing_auth',
                    newsletter_subscription: 'user.detail.properties.personal_info.privacy_settings.newsletter_subscription',
                    handle: 'user.detail.properties.personal_info.privacy_settings.handle',
                },
            },
            addressess: {
                billing: 'user.detail.properties.addressess.billing',
                shipping: 'user.detail.properties.addressess.shipping',
                first_address_line: 'user.detail.properties.addressess.first_address_line',
                second_address_line: 'user.detail.properties.addressess.second_address_line',
                city: 'user.detail.properties.addressess.city',
                zip: 'user.detail.properties.addressess.zip',
                state: 'user.detail.properties.addressess.state',
                country: 'user.detail.properties.addressess.country',
                phone: 'user.detail.properties.addressess.phone',
            },
            bottom_save_bar: {
                save_button: 'user.detail.properties.bottom_save_bar.save_button',
            },
        },
        wine: {
            personal_cantina: {
                title: 'user.detail.wine.personal_cantina.title',
                field_label: {
                    id: 'user.detail.wine.personal_cantina.field_label.id',
                    vintage: 'user.detail.wine.personal_cantina.field_label.vintage',
                    winery: 'user.detail.wine.personal_cantina.field_label.winery',
                    availability: 'user.detail.wine.personal_cantina.field_label.availability',
                    score: 'user.detail.wine.personal_cantina.field_label.score',
                    locked: 'user.detail.wine.personal_cantina.field_label.locked',
                },
                field_values: {
                    availability: {
                        quantity_text: 'user.detail.wine.personal_cantina.field_values.availability.quantity_text',
                        bottles: 'user.detail.wine.personal_cantina.field_values.availability.bottles',
                        smartbags: 'user.detail.wine.personal_cantina.field_values.availability.smartbags',
                    },
                }
            },
            user_vintages: {
                title: 'user.detail.wine.user_vintages.title',
                field_label: {
                    id: 'user.detail.wine.user_vintages.field_label.id',
                    full_name: 'user.detail.wine.user_vintages.field_label.full_name',
                    winery: 'user.detail.wine.user_vintages.field_label.winery',
                    locked: 'user.detail.wine.user_vintages.field_label.locked',
                    label: 'user.detail.wine.user_vintages.field_label.label',
                },
                field_values: {
                    full_name: 'user.detail.wine.user_vintages.field_values.full_name',
                }
            },
        }
    }
}
