export const COMPONENTS_DICTIONARY = {
    data_browser: {
        filters_panel: {
            side_bar_title: 'components.data_browser.filters_panel.side_bar_title'
        },
        columns_panel: {
            side_bar_title: 'components.data_browser.columns_panel.side_bar_title'
        },
        filtered_view_text: 'components.data_browser.filtered_view_text',
        export_panel: {
            header: 'components.data_browser.export_panel.header',
            items_count: 'components.data_browser.export_panel.items_count',
            body: 'components.data_browser.export_panel.body',
            button: 'components.data_browser.export_panel.button',
        },
        save_preset_panel: {
            header: 'components.data_browser.save_preset_panel.header',
            body: 'components.data_browser.save_preset_panel.body',
            button_text: 'components.data_browser.save_preset_panel.button_text',
        },
        table_header: {
            save_filter_view_button: 'components.data_browser.table_header.save_filter_view_button',
            delete_all_filters_button: 'components.data_browser.table_header.delete_all_filters_button',
            filters_chips_text: 'components.data_browser.table_header.filters_chips_text',
        },
    },
    table: {
        detail_button_tooltip: 'components.table.detail_button_tooltip'
    },
    detail: {
        albi_image_wrapper: {
            format_text: 'components.detail.albi_image_wrapper.format_text',
            resolution_text: 'components.detail.albi_image_wrapper.resolution_text',
        }
    }
}