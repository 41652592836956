import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BOTTLE_DICTIONARY } from "src/translations/dictionaries/bottle.dictionary";
import { COMPANY_DICTIONARY } from "src/translations/dictionaries/company.dictionary";
import { DISPENSER_DICTIONARY } from "src/translations/dictionaries/dispenser.dictionary";
import { SMARTBAG_DICTIONARY } from "src/translations/dictionaries/smartbag.dictionary";
import { USER_DICTIONARY } from "src/translations/dictionaries/user.dictionary";
import { VINTAGE_DICTIONARY } from "src/translations/dictionaries/vintage.dictionary";
import { WINE_DICTIONARY } from "src/translations/dictionaries/wine.dictionary";

@Pipe({
    name: 'filterChipTextPipe'
})
export class FilterChipTextPipe implements PipeTransform {

    constructor(private _translateService: TranslateService) {
    }

    //TODO:fix-translations

    transform(value: any, ...args: { key: string, feature: string }[]): string {
        let dictionary: { field_label: any, field_values: any };
        const argument: { key: string, feature: string } = args[0];
        switch (argument.feature) {
            case 'vintages':
                dictionary = VINTAGE_DICTIONARY;
                break;
            case 'wines':
                dictionary = WINE_DICTIONARY;
                break;
            case 'companies':
                dictionary = COMPANY_DICTIONARY;
                break;
            case 'users':
                dictionary = USER_DICTIONARY;
                break;
            case 'dispensers':
                dictionary = DISPENSER_DICTIONARY;
                break;
            case 'smartbags':
                dictionary = SMARTBAG_DICTIONARY;
                break;
            case 'bottles':
                dictionary = BOTTLE_DICTIONARY;
                break;
            default:
                console.log('ERR: fix import dictionary for feature in the filter chip text Pipe');
                return `${argument.key}:${value[argument.key]}`;
        }

        const filterRawValue = value[argument.key];
        const dictionaryKey = dictionary.field_label[argument.key as keyof typeof dictionary.field_label];
        let dictionaryValues: string | string[];
        if (!dictionaryKey) {
            return `${argument.key}: ${filterRawValue}`
        }
        if (Array.isArray(filterRawValue)) {
            dictionaryValues = filterRawValue.map(elm => dictionary.field_values[argument.key as keyof typeof dictionary.field_values]?.[elm]).filter(elm => elm !== undefined);
            dictionaryValues = dictionaryValues.length === 0 ? undefined : dictionaryValues;
        } else {
            dictionaryValues = dictionary.field_values[argument.key as keyof typeof dictionary.field_values]?.[filterRawValue];
        }
        return dictionaryValues ?
            `${this._translateService.instant(dictionaryKey)}: ${Array.isArray(dictionaryValues) ? dictionaryValues.filter(elm => elm !== undefined).map(elm => this._translateService.instant(elm)).join(', ') : this._translateService.instant(dictionaryValues)}` :
            `${this._translateService.instant(dictionaryKey)}: ${filterRawValue}`
    }
}