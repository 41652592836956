import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { FilterChoiceOptionType } from "@dashboard-components/data-browser/models/filters.model";
import { filter, map, takeUntil } from "rxjs";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";

@Component({
    selector: 'data-explorer-multiple-choice-filter',
    templateUrl: `./data-explorer-multiple-choice-filter.component.html`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DataExplorerMultipleChoiceFilterComponent extends DestroyableSubscription implements OnInit {

    @Input() filterLabel: string;
    @Input() filterKey: string;
    @Input() options: FilterChoiceOptionType[];

    @Output() filterChange = new EventEmitter();

    filterForm: FormGroup;

    constructor(private _fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.filterForm = this._fb.group({
            [this.filterKey + 'Selected']: new FormControl(false),
            [this.filterKey]: this._fb.array(new Array(this.options.length).fill(false))
        });

        this.filterForm.valueChanges.pipe(
            filter(formValue => formValue[this.filterKey] != undefined),
            map(value => ({ [this.filterKey]: value[this.filterKey].filter((elm: any) => elm !== false).map((elm: [string]) => elm[0]), [this.filterKey + 'Selected']: value[this.filterKey + 'Selected'] })),
            map(value => ({ [this.filterKey]: value[this.filterKey], selected: value[this.filterKey + 'Selected'] })),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(filter => this.filterChange.emit(filter));
    }
}