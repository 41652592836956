export const BOTTLE_DICTIONARY = {
    field_label: {
        id: 'bottle.field_label.id',
        model: 'bottle.field_label.model',
        vintage_id: 'bottle.field_label.vintage_id',
        created_by: 'bottle.field_label.created_by',
        paired_at: 'bottle.field_label.paired_at',
        owner_id: 'bottle.field_label.owner_id',
        status: 'bottle.field_label.status',
    },
    field_values: {},
    list: {
        table_title_label: 'bottle.list.table_title_label',
        table_new_item: 'bottle.list.table_new_item',
    },
    detail: {
        header: {
            packed_at: 'bottle.detail.header.packed_at'
        }
    }
}