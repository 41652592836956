<form [formGroup]="filterForm">
    <p-panel>
        <ng-template pTemplate="header">
            <p-checkbox [formControlName]="filterKey+'Selected'" [binary]="true" inputId="binary"></p-checkbox>
            <span class="font-semibold">{{filterLabel}}</span>
        </ng-template>
        <div *ngFor="let option of options; let idx = index" class="field-checkbox" [formArrayName]="this.filterKey">
            <p-checkbox [formControlName]="idx" [inputId]="option.key" [value]="option.key"></p-checkbox>
            <label [for]="option.key">{{ option.name }}</label>
        </div>
    </p-panel>
</form>