import { Injectable } from "@angular/core";
import { AuthenticationDetails, CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUser, CognitoUserPool, CognitoUserSession, ICognitoUserSessionData } from "amazon-cognito-identity-js";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { Observable, from } from "rxjs";
import { environment } from "src/environments/environment";

export const cognitoUserPoolId = environment.cognitoUserPool
export const cognitoClientId = environment.cognitoClientId

export type SessionInfoT = {
    accessToken: string,
    idToken: string,
    email: string,
    refreshToken: string,
}
@Injectable()
export class AuthService {

    private _requestedUrl: string;
    private _cognitoUser: CognitoUser;
    private _refreshInterval: NodeJS.Timeout;
    private readonly TOKEN_TIMEOUT = 15 * 60 * 1000;//15 min


    private _sessionInfo: SessionInfoT;

    public login(email: string, password: string): Observable<{ accessToken: string, email: string, idToken: string, refreshToken: string }> {
        const authenticationData = {
            Username: email,
            Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(
            authenticationData
        );
        const poolData = {
            UserPoolId: cognitoUserPoolId, // Your user pool id here
            ClientId: cognitoClientId // Your client id here
        };
        const userPool = new CognitoUserPool(poolData);
        const userData = {
            Username: email,
            Pool: userPool,
        };
        this._cognitoUser = new CognitoUser(userData);
        const loginPromise = new Promise<{ accessToken: string, email: string, idToken: string, refreshToken: string }>((resolve, reject) => {
            this._cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    const userGoups: string[] = result.getIdToken()?.payload?.['cognito:groups'];
                    if (!userGoups.includes('Admin')) {
                        reject('User is not admin');
                    }
                    const accessToken = result.getAccessToken().getJwtToken();
                    const idToken = result.getIdToken().getJwtToken();
                    const refreshToken = result.getRefreshToken().getToken();
                    resolve({
                        accessToken: accessToken,
                        email: email,
                        idToken: idToken,
                        refreshToken: refreshToken
                    });
                },

                onFailure: function (err) {
                    reject(err.message || JSON.stringify(err));
                },
            });
        })
        return from(loginPromise);
    }

    public startRefreshTokenTimer() {
        const sessionInfo = this.getSessionInfo();
        const refreshToken = new CognitoRefreshToken({ RefreshToken: sessionInfo.refreshToken });
        if (!this._refreshInterval) {
            this._refreshInterval = setInterval(() => {
                this._cognitoUser.refreshSession(refreshToken, (err: Error, session: CognitoUserSession) => {
                    if (err) {
                        console.log(err);
                    } else {
                        this.setSessionInfo({
                            ...sessionInfo,
                            accessToken: session.getAccessToken().getJwtToken(),
                            idToken: session.getIdToken().getJwtToken(),
                            refreshToken: session.getRefreshToken().getToken(),
                        });
                    }
                })
            }, this.TOKEN_TIMEOUT);
        }
    }

    public logout() {
        if (this._cognitoUser) {
            this._cognitoUser.signOut();
            this.setSessionInfo(null);
            clearInterval(this._refreshInterval);
            this._cognitoUser = undefined;
            window.location.reload();
        }
    }

    public checkToken(sessionInfo: SessionInfoT): Observable<boolean> {
        const verifier = CognitoJwtVerifier.create({
            userPoolId: cognitoUserPoolId,
            tokenUse: "access",
            clientId: cognitoClientId,
        });
        const checkPromise = new Promise<boolean>(async (resolve, reject) => {
            try {
                const payload = await verifier.verify(
                    sessionInfo.accessToken // the JWT as string
                );
                this._checkCognitoUser(sessionInfo);
                resolve(true);
            } catch {
                reject(false);
            }
        });
        return from(checkPromise);
    }

    public getSessionInfo(): SessionInfoT {
        return this._sessionInfo;
    }

    public setSessionInfo(sessionInfo: SessionInfoT): void {
        this._sessionInfo = sessionInfo;
        this._saveSessionDataLocally(sessionInfo);
    }

    private _checkCognitoUser(sessionInfo: SessionInfoT) {
        if (!this._cognitoUser) {
            this._cognitoUser = new CognitoUser({
                Username: sessionInfo.email,
                Pool: new CognitoUserPool({
                    UserPoolId: cognitoUserPoolId,
                    ClientId: cognitoClientId
                })
            });
            const cognitoSessionData: ICognitoUserSessionData = {
                IdToken: new CognitoIdToken({ IdToken: sessionInfo.idToken }),
                AccessToken: new CognitoAccessToken({ AccessToken: sessionInfo.accessToken }),
                RefreshToken: new CognitoRefreshToken({ RefreshToken: sessionInfo.refreshToken }),
            }
            this._cognitoUser.setSignInUserSession(new CognitoUserSession(cognitoSessionData))
        }
    }

    private _saveSessionDataLocally(sessionInfo: SessionInfoT | null) {
        window.localStorage.setItem('accessToken', sessionInfo?.accessToken);
        window.localStorage.setItem('email', sessionInfo?.email);
        window.localStorage.setItem('idToken', sessionInfo?.idToken);
        window.localStorage.setItem('refreshToken', sessionInfo?.refreshToken);
    }

    public getRequestedUrl(): string {
        return this._requestedUrl;
    }

    public setRequestedUrl(url: string): void {
        this._requestedUrl = url;
    }

}