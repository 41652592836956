<div class="flex justify-content-between align-items-center">
    <div class="font-bold text-lg">{{imageTitle}}</div>
    <p-overlayPanel [style]="{'padding':'0 !important'}" #op>
        <ng-template pTemplate="content">
            <div class="flex w-full flex-column p-fluid">
                <p-button class="w-full" label="Delete" icon="pi pi-trash" styleClass="p-button-sm"></p-button>
                <p-button class="w-full" (onClick)="onDefaultLabelUpload($event)" label="Upload" icon="pi pi-upload"
                    styleClass="p-button-sm"></p-button>
            </div>
        </ng-template>
    </p-overlayPanel>
    <div class="flex align-items-center">
        <p-tag severity="success" value="Active"></p-tag>
        <p-button class="p-button-xs" (click)="op.toggle($event)" icon="pi pi-chevron-down"
            styleClass="p-button-text"></p-button>
    </div>
</div>
{{imageDescription}}
<div>

</div>
<div class="image-container" *ngIf="imageUrl | imageStringBooleanPipe;else notFoundImage">
    <img height="240" alt="Header Image" [src]="imageUrl" onerror="this.src='assets/imgError/ImgError.png'">
</div>
<div>
    {{imageFormat}} {{imageResolution}}
</div>

<ng-template #notFoundImage>
    <img height="240" alt="Header Image" src="assets/imgError/ImgNotFound.png">
</ng-template>

<ng-template #loadingTemplate>

</ng-template>