<ng-container *ngIf="{
    isLoading: isLoading$ | async
} as vm">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form-container">
        <div class="form-header">
            <p-image src="assets/glass.png" alt="Image" width="60"></p-image>
            <h3>Cheers!</h3>
            <p>Enter your albicchiere user data to explore the dashboard</p>

        </div>
        <div class="login-input-container">
            <span class="p-input-icon-left p-float-label">
                <i class="pi pi-user"></i>
                <input pInputText id="username" formControlName="username" [disabled]="vm.isLoading" />
                <label htmlFor="username">Email</label>
            </span>
        </div>
        <div class="login-input-container">
            <span class="p-float-label p-input-icon-left">
                <!--
                <p-password formControlName="password" [disabled]="vm.isLoading" [toggleMask]="true"
                    [feedback]="false"></p-password>

                -->
                <i class="pi pi-key"></i>
                <input pInputText id="password" formControlName="password" type="{{seePassword?'text':'password'}}"
                    [disabled]="vm.isLoading" />
                <label for="password">Password</label>
            </span>
            <span class="see-password-span" (click)="handleSeePassword()"> <i
                    class="{{seePassword?'pi pi-eye':'pi pi-eye-slash'}}"></i></span>
        </div>
        <div class="login-input-container">
            <p-button class="ruby-button" *ngIf="!vm.isLoading" label="Login" styleClass="p-button-rounded"
                type="submit" [disabled]="vm.isLoading"></p-button>
        </div>
        <p-progressSpinner *ngIf="vm.isLoading"></p-progressSpinner>
        <p-image src="assets/albicchiere.png" alt="Image" height="25"></p-image>
    </form>
</ng-container>