export const VINTAGE_TRANSLATIONS_EN = {
    vintage: {
        field_label: {
            full_name: 'Full Name',
            id: 'Id',
            wine_id: 'Wine id',
            year: 'Year',
            year_details: 'Is Special Edition',
            alcohol: 'Alcohol',
            temperature: 'Temperature',
            description: 'Description',
            status: 'Status',
            moods: 'Moods',
            pairings: 'Pairings',
            allergen: 'Allergen',
            philosophy: 'Philosophy',
            is_claimed_by_winery: 'Claimed by winery',
            label_search: 'Label search',
            created_at: 'Created at',
            updated_at: 'Updated at',
            percentage: 'Percentage',
            allergens: 'Allergens',
            free_so2: 'Free SO2',
            awards: 'Awards',
            ratings_average: 'Ratings average',
            created_by: 'Created by',
            updated_by: 'Updated by',
        },
        list: {
            table_title_label: 'Vintages',
            table_new_item: 'new vintage',
            filter_missing_images: {
                name: 'Missing images',
                options: {
                    all: 'All',
                    bottle: 'Bottle',
                    dispenser: 'Dispenser',
                    smart_bag: 'Smart bag',
                    label: 'Label',
                }
            }
        },
        detail: {
            tabs: {
                properties: 'Properties',
                other_vintages: 'Other vintages'
            },
            properties: {
                details: {
                    title: 'Details',
                    specs: {
                        title: 'Specs',
                        year_label: 'Year',
                        service_temperature_label: 'Service temperature',
                        alcohol_label: 'Alcohol',
                    },
                    description: {
                        title: 'Vintage Description',
                        paragraph: 'Master - (language: {{lang}})',
                        not_found_text: 'Description({{lang}}) not found for this vintage',
                    },
                },
                pairings: {
                    title: 'Pairings',
                    foods: {
                        title: 'Pairings',
                        primary_pairings_label: 'Perfect foods',
                        secondary_pairings_label: 'Good foods',
                    },
                    others: {
                        title: 'Others',
                        moods_label: 'Moods',
                        allergens_label: 'Allergens',
                        philosophy_label: 'Philosophy',
                    },
                    awards: {
                        title: 'Awards',
                        awards_label: 'Awards',
                    },
                },
                images: {
                    title: 'Images',
                    label_image_title: 'Label',
                    smartbag_image_title: 'Smartbag',
                    bottle_image_title: 'Bottle',
                    others_title: 'Others',
                    others_description: 'Other images relative to this vintage',

                },
                save_panel: {
                    submit_button: 'save',
                },
            },
            other_vintages: {
                table_title_label: 'Other vintages',
                table_new_item: 'Add vintage',
            }
        },
        create: {
            page_title: 'New Vintage',
            page_description: 'This wine will be added to the Albicchiere Database!',
            steps: {
                winery: 'Winery',
                wine: 'Wine',
                vintage: 'Vintage',
                recap: 'Recap',
            },
            button_labels: {
                next: 'Next',
                prev: 'Prev',
                save: 'Save',
            },
            errors: {
                required_field: 'Required field',
                value_error: 'Value error',
            },
            winery: {
                step_title: 'Step 1',
                autocomplete_title: 'Winery Name',
                autocomplete_description: 'Insert winery name...',
                create_new_label: 'Create new winery',
                autocomplete_item: {
                    name: 'name: {{name}}',
                    type: 'type: {{type}}',
                },
            },
            wine: {
                step_title: 'Step 2',
                autocomplete_title: 'Wine Name',
                autocomplete_description: 'Insert wine name...',
                create_new_label: 'Create new winery',
                wine_info: {
                    title: 'Wine Information'
                },
                autocomplete_item: {
                    name: 'Name: {{name}}',
                    color: 'Color: {{color}}',
                },
            },
            vintage: {
                step_title: 'Step 3',
                year_picker_title: 'Vintage Year',
                year_picker_description: 'Insert year of the vintage...',
                special_definition_label: 'Insert special definition for this year....',
                non_vintage: 'Non vintage',
                vintage_details: {
                    title: 'Vintage details',
                    description_label: 'Description',
                    language_select_label: 'Select Master Language:'
                },
            },
            recap: {
                description_label: 'Description - {{lang}}',
            }
        }
    }
}