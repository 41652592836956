<form class="flex flex-column gap-3" [formGroup]="filterForm">
    <p-panel>
        <ng-template pTemplate="header">
            <p-checkbox [formControlName]="filterKey+'Selected'" [binary]="true" inputId="binary"></p-checkbox>
            <span class="font-semibold">{{filterLabel}}</span>
            <div>
                {{sliderValue | async | sliderValueFormatPipe}}
            </div>
        </ng-template>
        <p-slider class="w-full" *ngIf="sliderConfig" (ngModelChange)="changeSliderValue($event)"
            [formControlName]="filterKey" [range]="isRange" [min]="sliderConfig.min" [max]="sliderConfig.max"
            [step]="sliderConfig.step"></p-slider>
    </p-panel>
</form>