<ng-container *ngIf="{
    glassess: glassess$ | async,
} as vm">
    <div class="flex">
        <ng-container *ngFor="let glass of vm.glassess">
            <ng-container [ngSwitch]="glass.glassType">
                <div *ngSwitchCase="'full'"><img [height]="32" src="assets/glass-full.svg" alt=""></div>
                <div *ngSwitchCase="'half'"><img [height]="32" src="assets/glass-half.svg" alt=""></div>
                <div *ngSwitchDefault><img [height]="32" src="assets/glass-empty.svg" alt=""></div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>