export const ENVIRONMENT_CONFIG = {
    dev: {
        apiUrl: 'https://api-dev.albicchiere.com',
        cognitoUserPool: 'eu-west-1_BnxRNXEeL',//'eu-west-1_XwvKxnt3q',
        cognitoClientId: '2u35dr6oo4vajnkdbo8qscqh45'//'2b1cdufqm7hsc70oukuak9njai'
    },
    staging: {
        apiUrl: 'https://api-staging.albicchiere.com',
        cognitoUserPool: 'us-east-1_1zaTQGSFY',//'us-east-1_1zaTQGSFY',
        cognitoClientId: '3s0l077mgm4h0f4lvk4682shnu'//'3s0l077mgm4h0f4lvk4682shnu'
    },
    prod: {
        apiUrl: 'https://api.albicchiere.com',
        cognitoUserPool: 'eu-west-1_Mq2rXIDUU',//'us-east-1_jYY2V9wgy',
        cognitoClientId: '1q9tngbjg1bqaenoj2km8qmsl3'//'3er9mq55u4i3egr1gdjkkspjqb'
    }
}