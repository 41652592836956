import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { PaginatorState } from "primeng/paginator";
import { Observable, map } from "rxjs";
import { FeatureService } from "src/services/feature.service";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { ColumnsSelectorService } from "../data-browser/columns-selector/columns-selector.service";
import { DATA_BROWSER_PAGE_ACTIONS } from "../data-browser/store/data-browser.actions";
import { PageInfo } from "../data-browser/store/data-browser.state";

export type TableColumn = {
    name: string;
    key: string;
    isSortable?: boolean;
    cellStyleWidth: 'table-cell-width-sm' | 'table-cell-width-md' | 'table-cell-width-lg' | 'table-cell-width-xl'
}

@Component({
    selector: 'table-wrapper',
    templateUrl: `./table.component.html`,
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TableWrapperComponent implements AfterViewInit {

    public tableDictionary = COMPONENTS_DICTIONARY.table

    @Input() columns: TableColumn[];
    @Input() items: any[];
    @Input() pageInfo: PageInfo;

    @Input() rowItemTemplate: TemplateRef<any>;

    public tableColumns$: Observable<TableColumn[]>;
    @Output() pageInfoChange = new EventEmitter<PaginatorState>();

    constructor(private _elementRef: ElementRef, private _columnsSelectorService: ColumnsSelectorService,
        private _store: Store, private _featureService: FeatureService, private _router: Router) {
        this.tableColumns$ = this._columnsSelectorService.selectedColumns$.pipe(
            map(selectedColumns => this.columns.filter(elm => selectedColumns.includes(elm.key)))
        );
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('table-container');
    }

    onPageChange(event: PaginatorState): void {
        this.pageInfoChange.emit({ ...event, page: event.page + 1, });
    }

    selectedItemsChange(selectedItems: any[]) {
        this._store.dispatch(DATA_BROWSER_PAGE_ACTIONS(this._featureService.getFeatureName()).selectedItems({ selectedItems }))
    }

    handleDetailPageButton(id: string) {
        this._router.navigate([this._router.url, id]);
    }
}