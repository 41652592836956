import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from "@angular/core";

export enum DetailSectionComponentWidthEnum {
    FULL = 'full',
    HALF = 'half',
}
@Component({
    selector: 'detail-section',
    templateUrl: `./detail-section.component.html`,
    styleUrls: ['./detail-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DetailSectionComponent implements AfterViewInit {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() customTitleStyle: object = {};
    @Input() width: DetailSectionComponentWidthEnum = DetailSectionComponentWidthEnum.FULL

    constructor(private _elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add(...[
            'detail-section',
            (this.width === DetailSectionComponentWidthEnum.FULL ? 'detail-section--full-width' : 'detail-section--half-width')]);
    }
}