export const COMPANY_TRANSLATIONS_EN = {
    company: {
        field_label: {
            name: 'Name',
            type: 'Type',
            status: 'Status',
            id: 'Id',
            phone: 'Phone',
            email: 'Email',
            created_at: 'Created at',
            updated_at: 'Updated at',
            percentage: 'Percentage',
            country: 'Country',
            state: 'State',
            city: 'City',
            vat: 'Vat',
            created_by: 'Created by',
        },
        field_values: {
            company_type: {
                winery: 'Winery',
                professional: 'Professional',
                reseller: 'Reseller'
            },
        },
        list: {
            table_title_label: 'Companies',
            table_new_item: 'New Company',
        },
        detail: {
            properties: {
                details: {
                    title: 'Details',
                    company_info: {
                        title: 'Company Info',
                        company_name: 'Company Name',
                        vat: 'Vat',
                        brand_name: 'Brand Name',
                    },
                    contacts: {
                        title: 'Contacts',
                        email: 'Email',
                        website: 'Website',
                        phone: 'Phone',
                    },
                    social: {
                        title: 'Social',
                        facebook: 'Facebook',
                        instagram: 'Instagram',
                        twitter: 'Twitter',
                    }
                },
                bottom_save_panel: {
                    save_button: 'Save',
                    updated_by_text: 'company.detail.properties.bottom_save_panel.updated_by_text',
                }
            }
        }
    }
}