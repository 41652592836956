import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { TabViewChangeEvent } from "primeng/tabview";

export type DetailNavigationTab = {
    title: string,
    url: string,
    disabled?: boolean,
}

@Component({
    selector: 'detail-htabs',
    templateUrl: `./detail-htabs.component.html`,
    styleUrls: ['./detail-htabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DetailHtabs {

    @Input() activeIndex: number;
    @Input() hTabs: DetailNavigationTab[];

    @Output() onChange = new EventEmitter<TabViewChangeEvent>();

    constructor(private _elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('detail-htabs');
    }

    onChangeTab(event: TabViewChangeEvent) {
        this.onChange.emit(event);
    }
}