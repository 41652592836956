export const COMPANY_TRANSLATIONS_IT = {
    company: {
        list: {
            table_title_label: 'Aziende',
            table_new_item: 'Crea Azienda',
            columns: {
                name: 'Nome',
                type: 'Tipo',
                status: 'Stato',
                percentage: 'Percentuale'
            },
            filters: {
                company_type: {
                    title: 'Tipo Azienda',
                    options: {
                        winery: 'Cantina',
                        professional: 'Ristorante',
                        reseller: 'Rivendita'
                    }
                }
            },
            main_filters: {
                all: 'Tutti',
                pending: 'In attesa',
                active: 'Attivi',
                inactive: 'Inattivi'
            },
            speed_dial: {
                export: 'Esporta',
                deduplicate: 'Deduplica',
                delete: 'Elimina',
            },
        }
    }
}