<ng-container *ngIf="{
    items: items$ | async,
    isLoading: isLoading$ | async,
    pageInfo: pageInfo$ | async,
    isFilterVisible: isFilterVisible$ | async,
    isSearchInputVisible: isSearchInputVisible$ | async,
    searchText: searchText$ | async,
    isDeatilButtonActive : isDeatilButtonActive$ | async,
} as vm">
    <div class="flex justify-content-between pb-4">
        <div class="text-xl font-bold">{{tableTitle}}</div>
        <div>
            <input class="mr-3" *ngIf="vm.isSearchInputVisible" type="text" pInputText [ngModel]="vm.searchText"
                (ngModelChange)="updateSearchText($event)" />
            <p-button type="button" class="mr-2" styleClass="p-button-outlined" (click)="toggleSearchInput()"
                icon="pi pi-search"></p-button>
            <p-button type="button" class="mr-2" (click)="toggleFilterPanel()" icon="pi pi-filter"></p-button>
        </div>
    </div>
    <p-sidebar [(visible)]="vm.isFilterVisible" position="left" styleClass="w-30rem" (onHide)="toggleFilterPanel()">
        <h3>{{tableTitle}} Filters</h3>
        <ng-container *ngFor="let filter of filtersConfig">
            <ng-container [ngSwitch]="filter.type">
                <data-explorer-calendar-filter *ngSwitchCase="FilterTypeEnum.CALENDAR"
                    [filterLabel]="filter.filterLabel" [filterKey]="filter.filterField"
                    [calendarConfig]="filter.calendarConfig"
                    (filterChange)="updateFilters($event)"></data-explorer-calendar-filter>
                <data-explorer-slider-filter *ngSwitchCase="FilterTypeEnum.SLIDER" [filterLabel]="filter.filterLabel"
                    [isRange]="false" [filterKey]="filter.filterField" [sliderConfig]="filter.sliderConfig"
                    (filterChange)="updateFilters($event)"></data-explorer-slider-filter>
                <data-explorer-slider-filter *ngSwitchCase="FilterTypeEnum.SLIDER_RANGE"
                    [filterLabel]="filter.filterLabel" [isRange]="true" [filterKey]="filter.filterField"
                    [sliderConfig]="filter.sliderConfig"
                    (filterChange)="updateFilters($event)"></data-explorer-slider-filter>
                <data-explorer-single-choice-filter *ngSwitchCase="FilterTypeEnum.SINGLE"
                    [filterLabel]="filter.filterLabel" [filterKey]="filter.filterField" [options]="filter.options"
                    (filterChange)="updateFilters($event)"></data-explorer-single-choice-filter>
                <data-explorer-multiple-choice-filter *ngSwitchCase="FilterTypeEnum.MULTIPLE"
                    [filterLabel]="filter.filterLabel" [filterKey]="filter.filterField" [options]="filter.options"
                    (filterChange)="updateFilters($event)"></data-explorer-multiple-choice-filter>
                <data-explorer-selection-filter *ngSwitchCase="FilterTypeEnum.SINGLE_SELECTION"
                    [filterLabel]="filter.filterLabel" [isMultipleSelection]="false" [filterKey]="filter.filterField"
                    [options]="filter.options" [endpointConfig]="filter.endpointConfig"
                    (filterChange)="updateFilters($event)"></data-explorer-selection-filter>
                <data-explorer-selection-filter *ngSwitchCase="FilterTypeEnum.MULTIPLE_SELECTION"
                    [filterLabel]="filter.filterLabel" [isMultipleSelection]="true" [filterKey]="filter.filterField"
                    [options]="filter.options" [endpointConfig]="filter.endpointConfig"
                    (filterChange)="updateFilters($event)"></data-explorer-selection-filter>
            </ng-container>
        </ng-container>
    </p-sidebar>
    <ng-container>
        <p-table [value]="vm.items" styleClass="table-style">
            <ng-template pTemplate="header">
                <tr>
                    <ng-container *ngFor="let column of tableColumns">
                        <th>{{column.name}}</th>
                    </ng-container>
                    <th class="detail-button-container" alignFrozen="right" pFrozenColumn [frozen]="true"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <ng-container [ngTemplateOutlet]="rowItemTemplate" [ngTemplateOutletContext]="{$implicit: item}">
                    </ng-container>
                    <td class="detail-button-container" alignFrozen="right" pFrozenColumn [frozen]="true">
                        <p-button [disabled]="vm.isDeatilButtonActive" icon="pi pi-eye" styleClass="p-button-text"
                            [pTooltip]="'Detail page'" [tooltipPosition]="'top'"
                            (onClick)="handleDetailPageButton(item._id)"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="spinner-container" *ngIf="vm.isLoading">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <p-paginator (onPageChange)="updatePaginator($event)" [rows]="vm.pageInfo.size"
            [first]="(vm.pageInfo.page - 1)*vm.pageInfo.size"
            [totalRecords]="vm.pageInfo.size * vm.pageInfo.pages"></p-paginator>
    </ng-container>
</ng-container>