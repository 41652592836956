import { MemoizedSelector, createSelector } from '@ngrx/store';
import { DataBrowserState } from './data-browser.state';

export type DATA_BROWSER_SELECTORS_TYPE = {
    selectDataBrowserConfig: MemoizedSelector<any, any, (s1: any) => any>;
    selectIsFilterVisible: MemoizedSelector<any, any, (s1: any) => any>;
    selectIsColumnsSelecotorVisible: MemoizedSelector<any, any, (s1: any) => any>;
    selectFilters: MemoizedSelector<any, any, (s1: any) => any>;
    selectItems: MemoizedSelector<any, any, (s1: any) => any>;
    selectPageInfo: MemoizedSelector<any, any, (s1: any) => any>;
    selectIsLoading: MemoizedSelector<any, any, (s1: any) => any>;
    selectSelectedItems: MemoizedSelector<any, any, (s1: any) => any>;
};

const selectDataBrowser = (feature: string) => (state: any): DataBrowserState => state[feature];

export const DATA_BROWSER_SELECTORS = (feature: string) => {
    return {
        selectDataBrowserConfig: createSelector(
            selectDataBrowser(feature),
            (state) => state.config
        ),
        selectIsFilterVisible: createSelector(
            selectDataBrowser(feature),
            (state) => state.isFilterVisible
        ),
        selectIsColumnsSelecotorVisible: createSelector(
            selectDataBrowser(feature),
            (state) => state.isColumnsSelecotorVisible
        ),

        selectFilters: createSelector(
            selectDataBrowser(feature),
            (state) => state.filters
        ),

        selectItems: createSelector(
            selectDataBrowser(feature),
            (state) => state.items
        ),

        selectPageInfo: createSelector(
            selectDataBrowser(feature),
            (state) => state.pageInfo
        ),

        selectIsLoading: createSelector(
            selectDataBrowser(feature),
            (state) => state.isLoading
        ),

        selectSelectedItems: createSelector(
            selectDataBrowser(feature),
            (state) => state.selectedItems
        ),



    }
}

