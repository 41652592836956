export const COMPONENTS_TRANSLATIONS_EN = {
    components: {
        data_browser: {
            filters_panel: {
                side_bar_title: 'Filters {{feature}} for'
            },
            columns_panel: {
                side_bar_title: 'Manage columns'
            },
            filtered_view_text: '(Filtered as: {{filterViewName}})',
            export_panel: {
                header: 'Export data',
                items_count: 'You are exporting: {{count}} items',
                body: 'You can export at maximum the number of record that you see on the page (10,25,50). Will be exported only the field selected in the column.',
                button: 'Export',
            },
            save_preset_panel: {
                header: 'Save filtered view',
                body: 'to proceed, assign a name to the filtered view that you want to save.',
                button_text: 'Save',
            },
            table_header: {
                save_filter_view_button: 'Save Preview',
                delete_all_filters_button: 'Delete filters',
                filters_chips_text: 'Filtered for:',
            },
        },
        table: {
            detail_button_tooltip: 'Detail page'
        },
        detail: {
            albi_image_wrapper: {
                format_text: 'Format: {{format}} ',
                resolution_text: 'Resolution: {{resolution}} ',
            }
        }
    }
}