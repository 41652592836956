import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { FilterChoiceOptionType } from "@dashboard-components/data-browser/models/filters.model";
import { DATA_BROWSER_SELECTORS, DATA_BROWSER_SELECTORS_TYPE } from "@dashboard-components/data-browser/store/data-browser.selectors";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil, tap } from "rxjs";
import { FeatureService } from "src/services/feature.service";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";

@Component({
    selector: 'multiple-choice-filter',
    templateUrl: `./multiple-choice-filter.component.html`,
    styleUrls: ['./multiple-choice-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class MultipleChoiceFilterComponent extends DestroyableSubscription implements OnInit {

    @Input() filterLabel: string;
    @Input() filterKey: string;
    @Input() options: FilterChoiceOptionType[];

    @Output() filterChange = new EventEmitter();

    filterForm: FormGroup;
    private _DATA_BROWSER_SELECTORS: DATA_BROWSER_SELECTORS_TYPE;

    constructor(private _fb: FormBuilder, private _store: Store, private _featureService: FeatureService) {
        super();
        this._DATA_BROWSER_SELECTORS = DATA_BROWSER_SELECTORS(this._featureService.getFeatureName());
    }

    ngOnInit(): void {
        this.filterForm = this._fb.group({
            [this.filterKey + 'Selected']: new FormControl(false),
            [this.filterKey]: this._fb.array(new Array(this.options.length).fill(false))
        });

        this._store.select(this._DATA_BROWSER_SELECTORS.selectFilters).pipe(
            tap(filters => {
                if (Object.keys(filters).includes(this.filterKey) && !this.filterForm.controls[this.filterKey + 'Selected'].getRawValue()) {
                    //filters include the filter key but the filter key in the side panel is not selected
                    this.filterForm.controls[this.filterKey + 'Selected'].setValue(true)
                    const optionsValue = this.options.map(el => filters[this.filterKey].includes(el.key) ? [el.key] : false)
                    this.filterForm.controls[this.filterKey].setValue(optionsValue)
                } else if (!Object.keys(filters).includes(this.filterKey) && this.filterForm.controls[this.filterKey + 'Selected'].getRawValue()) {
                    //filters don't include the filter key but the filter key in the side panel is selected
                    this.filterForm.controls[this.filterKey + 'Selected'].setValue(false)
                }
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe()

        this.filterForm.valueChanges.pipe(
            filter(formValue => formValue[this.filterKey] != undefined),
            map(value => ({ [this.filterKey]: value[this.filterKey].filter((elm: any) => elm !== false).map((elm: [string]) => elm[0]), [this.filterKey + 'Selected']: value[this.filterKey + 'Selected'] })),
            map(value => ({ [this.filterKey]: value[this.filterKey], selected: value[this.filterKey + 'Selected'] })),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(filter => this.filterChange.emit(filter));
    }
}