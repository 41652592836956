import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'includedInArrayPipe'
})
export class IncludedInArrayPipe implements PipeTransform {
    transform(value: any[], ...args: any[]) {
        if (args[0]) {
            const array = args[0] as any[]
            return array.includes(value);
        } else {
            throw new Error('Need array as args[0]');
        }
    }
}