export const BOTTLE_TRANSLATE_EN = {
    bottle: {
        field_label: {
            id: 'Id',
            model: 'Model id',
            vintage_id: 'Vintage id',
            created_by: 'Created by',
            paired_at: 'Paired at',
            owner_id: 'Owner id',
            status: 'Status',
        },
        list: {
            table_title_label: 'Bottles',
            table_new_item: 'New bottle',
        },
        detail: {
            header: {
                packed_at: 'Packed at'
            }
        }
    }
}