export const SMARTBAG_DICTIONARY = {
    field_label: {
        id: 'smartbag.field_label.id',
        serial: 'smartbag.field_label.serial',
        quality_score: 'smartbag.field_label.quality_score',
        type: 'smartbag.field_label.type',
        updated_at: 'smartbag.field_label.updated_at',
        owner_id: 'smartbag.field_label.owner_id',
        paired_at: 'smartbag.field_label.paired_at',
        opened_at: 'smartbag.field_label.opened_at',
    },
    field_values: {},
    list: {
        table_title_label: 'smartbag.list.table_title_label',
        table_new_item: 'smartbag.list.table_new_item',
    },
    detail: {
        header: {
            packed_at: 'smartbag.detail.header.packed_at'
        }
    }
}