import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { FilterPreset } from "../models/filters.model";

@Pipe({
    name: 'resolveFilteredviewTextPipe'
})
export class ResolveFilteredviewTextPipe implements PipeTransform {

    constructor(private _translateService: TranslateService) { }

    transform(value: FilterPreset, ...args: any[]): string {
        const dictionary = COMPONENTS_DICTIONARY.data_browser

        return value ? this._translateService.instant(dictionary.filtered_view_text, { filterViewName: value.name }) : ''
    }
}