import { createAction, props } from '@ngrx/store';
import { SessionInfoT } from 'src/services/auth.service';
import { LOGIN_FEATURE } from './login.state';

export const LOGIN_PAGE_ACTIONS = {
    clickLogin: createAction(`[${LOGIN_FEATURE}] User Login`,
        props<{ username: string, password: string }>()
    ),
    initLoginStateFromStorage: createAction(`[${LOGIN_FEATURE}] Init Login State`)
};

export const LOGIN_API_ACTIONS = {
    loginSuccess: createAction(
        `[${LOGIN_FEATURE}] Login Success`,
        props<SessionInfoT>()
    ),
    loginFailure: createAction(
        `[${LOGIN_FEATURE}] Login Failure`,
        props<{ error: string }>()
    )
};

