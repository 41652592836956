import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterChoiceOptionType } from "@dashboard-components/data-browser/models/filters.model";
import { DATA_BROWSER_SELECTORS, DATA_BROWSER_SELECTORS_TYPE } from "@dashboard-components/data-browser/store/data-browser.selectors";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, delay, filter, map, of, switchMap, take, takeUntil, tap } from "rxjs";
import { BackendService } from "src/services/backend.service";
import { FeatureService } from "src/services/feature.service";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";

export type SelectionFilterEndpointConfigType = {
    endpoint: string;
    responseField: string;
    searchFilter: string;
    labelField: string;
    keyField: string;
};


interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}
@Component({
    selector: 'selection-filter',
    templateUrl: `./selection-filter.component.html`,
    styleUrls: ['./selection-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class SelectionFilterComponent extends DestroyableSubscription implements OnInit {

    @Input() filterLabel: string;
    @Input() filterKey: string;

    @Input() isMultipleSelection: boolean = false;

    @Input() options: FilterChoiceOptionType[];
    @Input() endpointConfig: SelectionFilterEndpointConfigType;

    @Output() filterChange = new EventEmitter();

    filterForm: FormGroup;

    queryState$ = new BehaviorSubject('');
    suggestedOptions$: Observable<any[]>;
    private _DATA_BROWSER_SELECTORS: DATA_BROWSER_SELECTORS_TYPE;

    constructor(private _backendService: BackendService, private _store: Store, private _featureService: FeatureService) {
        super();
        this._DATA_BROWSER_SELECTORS = DATA_BROWSER_SELECTORS(this._featureService.getFeatureName());
    }

    ngOnInit(): void {
        this.filterForm = new FormGroup({
            [this.filterKey + 'Selected']: new FormControl(false),
            [this.filterKey]: new FormControl<object | string | null>(null)
        });


        this._store.select(this._DATA_BROWSER_SELECTORS.selectFilters).pipe(
            tap(filters => {
                if (Object.keys(filters).includes(this.filterKey) && !this.filterForm.controls[this.filterKey + 'Selected'].getRawValue()) {
                    //filters include the filter key but the filter key in the side panel is not selected
                    this.filterForm.controls[this.filterKey + 'Selected'].setValue(true)
                    this.filterForm.controls[this.filterKey].setValue({ key: filters[this.filterKey], name: filters[this.filterKey] })
                } else if (!Object.keys(filters).includes(this.filterKey) && this.filterForm.controls[this.filterKey + 'Selected'].getRawValue()) {
                    //filters don't include the filter key but the filter key in the side panel is selected
                    this.filterForm.controls[this.filterKey + 'Selected'].setValue(false)
                }
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe()

        this.filterForm.valueChanges.pipe(
            filter(formValue => formValue[this.filterKey] != undefined),
            map(formValue => {
                return this.isMultipleSelection ?
                    {
                        [this.filterKey]: formValue[this.filterKey].map((elm: FilterChoiceOptionType | string) =>
                            (typeof elm === 'string' ? elm : elm.key)), selected: formValue[this.filterKey + 'Selected']
                    } :
                    { [this.filterKey]: (typeof (formValue[this.filterKey] as FilterChoiceOptionType | string) === 'string' ? formValue[this.filterKey] : formValue[this.filterKey].key), selected: formValue[this.filterKey + 'Selected'] };
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(filter => this.filterChange.emit(filter));

        this.suggestedOptions$ = this.queryState$.pipe(
            delay(200),
            switchMap(query => this._getSuggestedOptions(query))
        )
    }

    private _getSuggestedOptions(query: string): Observable<FilterChoiceOptionType[]> {
        if (query.length === 0) {
            return of([]);
        }
        if (this.endpointConfig) {
            return this._backendService.get(`${this.endpointConfig.endpoint}?${this.endpointConfig.searchFilter}=${query}`).pipe(
                take(1),
                map((response: any) => {
                    let nestedValue = response
                    if (this.endpointConfig.responseField.includes('.')) {
                        const keys = this.endpointConfig.responseField.split('.')
                        for (const key of keys) {
                            nestedValue = nestedValue[key]
                        }
                    } else {
                        nestedValue = nestedValue[this.endpointConfig.responseField]
                    }
                    return (nestedValue as any[]).map(item => ({
                        key: item[this.endpointConfig.keyField],
                        name: item[this.endpointConfig.labelField]
                    }));
                }),
            );
        } else {
            let filtered: any[] = [];

            for (let i = 0; i < this.options.length; i++) {
                let option = this.options[i];
                if (option.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(option);
                }
            }

            return of(filtered);
        }
    }

    filterOption(event: AutoCompleteCompleteEvent) {
        const query = event.query;
        this.queryState$.next(query);
    }
}