import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: 'resolveNestedObjectFieldPipe'
})
export class ResolveNestedObjectFieldPipe implements PipeTransform {

    constructor() { }

    transform(item: any, ...args: string[]) {
        const fields = args[0].split('.');
        let result: any = item;
        for (const field of fields) {
            result = result[field];
            if (!result) {
                break;
            }
        }
        return result;
    }
}