export const DASHBOARD_TRANSLATIONS_EN = {
    dashboard: {
        tabs: {
            wines: 'Wines',
            vintages: 'Vintages',
            companies: 'Companies',
            users: 'Users',
            dispensers: 'Dispensers',
            tags: 'Tags',
            smartbags: 'Smartbags',
            bottles: 'Bottles',
        },
        avatar_text: 'cheers',
        logout_button: 'Logout',
    },
}