import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DATA_BROWSER_SELECTORS, DATA_BROWSER_SELECTORS_TYPE } from "@dashboard-components/data-browser/store/data-browser.selectors";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil, tap } from "rxjs";
import { FeatureService } from "src/services/feature.service";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";


export type CalendarFilterConfigType = {
    type?: 'yearPicker',
    maxDate?: Date,
    minDate?: Date
};

@Component({
    selector: 'calendar-filter',
    templateUrl: `./calendar-filter.component.html`,
    styleUrls: ['./calendar-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CalendarFilterComponent extends DestroyableSubscription implements OnInit {

    @Input() filterLabel: string;
    @Input() filterKey: string;

    @Input() calendarConfig: CalendarFilterConfigType;

    @Output() filterChange = new EventEmitter();

    filterForm: FormGroup;
    private _DATA_BROWSER_SELECTORS: DATA_BROWSER_SELECTORS_TYPE;

    constructor(private _store: Store, private _featureService: FeatureService) {
        super();
        this._DATA_BROWSER_SELECTORS = DATA_BROWSER_SELECTORS(this._featureService.getFeatureName());
    }

    ngOnInit(): void {
        this.filterForm = new FormGroup({
            [this.filterKey + 'Selected']: new FormControl(false),
            [this.filterKey + 'Selected']: new FormControl(false),
            [this.filterKey]: new FormControl<Date | null>(null)
        });

        this._store.select(this._DATA_BROWSER_SELECTORS.selectFilters).pipe(
            tap(filters => {
                if (Object.keys(filters).includes(this.filterKey) && !this.filterForm.controls[this.filterKey + 'Selected'].getRawValue()) {
                    //filters include the filter key but the filter key in the side panel is not selected
                    this.filterForm.controls[this.filterKey + 'Selected'].setValue(true)
                    this.filterForm.controls[this.filterKey].setValue(new Date(filters[this.filterKey]))
                } else if (!Object.keys(filters).includes(this.filterKey) && this.filterForm.controls[this.filterKey + 'Selected'].getRawValue()) {
                    //filters don't include the filter key but the filter key in the side panel is selected
                    this.filterForm.controls[this.filterKey + 'Selected'].setValue(false)
                }
            }),
            takeUntil(this.ngUnsubscribe)
        ).subscribe()

        if (!this.calendarConfig) {
            this.calendarConfig = {
            };
        }

        this.filterForm.valueChanges.pipe(
            filter(formValue => formValue[this.filterKey] != undefined),
            map(elm => ({
                [this.filterKey]:
                    this.calendarConfig.type === 'yearPicker' ? (elm[this.filterKey] as Date).getFullYear() : (elm[this.filterKey] as Date).toISOString(), selected: elm[this.filterKey + 'Selected']
            })),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(filter => this.filterChange.emit(filter));
    }
}