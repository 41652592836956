import { createAction, props } from '@ngrx/store';
import { PaginatorState } from 'primeng/paginator';
import { DataBrowserConfig } from '../data-browser.component';
import { DATA_BROWSER_STATE_FEATURE, PageInfo } from './data-browser.state';

export const DATA_BROWSER_GENERIC_ACTIONS = {
    getItems: createAction(`[${DATA_BROWSER_STATE_FEATURE}] Get Items`, props<{ featureName: string, filters: { [key: string]: any }, pageInfo: PageInfo }>()),
};

export const DATA_BROWSER_PAGE_ACTIONS = (featureName: string) => ({
    initDataBrowser: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Init Data Browser`,
        props<{ config: DataBrowserConfig }>()),
    toggleFilterPanel: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Toggle Filter Panel`),
    toggleColumnsSelectorPanel: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Toggle Columns Selector Panel`),
    updateFilters: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Update Filters`,
        props<{ filters: { [key: string]: any } }>()
    ),
    setViewFilters: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Set View Filters`,
        props<{ filters: { [key: string]: any } }>()
    ),
    removeFilters: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Remove Filters`,
        props<{ filters: { [key: string]: any } }>()
    ),
    updatePaginator: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Update Paginator`,
        props<{ paginatorEvent: PaginatorState }>()
    ),
    speedDialDownload: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Speed Dial Download`),
    speedDialRefresh: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Speed Dial Refresh`),

    selectedItems: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Selected Items`, props<{ selectedItems: any[] }>()),
    destroyDataBrowser: createAction(`[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Destroy Data Browser`),
});

export const DATA_BROWSER_API_ACTIONS = (featureName: string) => ({
    getItemsSuccess: createAction(
        `[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Get Items Success`,
        props<{ items: any[], pageInfo: PageInfo }>()
    ),
    getItemsFailure: createAction(
        `[${DATA_BROWSER_STATE_FEATURE} ${featureName}] Get Items Failure`,
        props<{ error: string }>()
    )
});

