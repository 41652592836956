export const COMPANY_DICTIONARY = {
    field_label: {
        name: 'company.field_label.name',
        type: 'company.field_label.type',
        status: 'company.field_label.status',
        id: 'company.field_label.id',
        phone: 'company.field_label.phone',
        email: 'company.field_label.email',
        created_at: 'company.field_label.created_at',
        updated_at: 'company.field_label.updated_at',
        percentage: 'company.field_label.percentage',
        country: 'company.field_label.country',
        state: 'company.field_label.state',
        city: 'company.field_label.city',
        vat: 'company.field_label.vat',
        created_by: 'company.field_label.created_by',
    },
    field_values: {
        company_type: {
            winery: 'company.field_values.company_type.winery',
            professional: 'company.field_values.company_type.professional',
            reseller: 'company.field_values.company_type.reseller',
        },
    },
    list: {
        table_title_label: 'company.list.table_title_label',
        table_new_item: 'company.list.table_new_item',
    },
    detail: {
        properties: {
            details: {
                title: 'company.detail.properties.details.title',
                company_info: {
                    title: 'company.detail.properties.details.company_info.title',
                    company_name: 'company.detail.properties.details.company_info.company_name',
                    vat: 'company.detail.properties.details.company_info.vat',
                    brand_name: 'company.detail.properties.details.company_info.brand_name',
                },
                contacts: {
                    title: 'company.detail.properties.details.contacts.title',
                    email: 'company.detail.properties.details.contacts.email',
                    website: 'company.detail.properties.details.contacts.website',
                    phone: 'company.detail.properties.details.contacts.phone',
                },
                social: {
                    title: 'company.detail.properties.details.social.title',
                    facebook: 'company.detail.properties.details.social.facebook',
                    instagram: 'company.detail.properties.details.social.instagram',
                    twitter: 'company.detail.properties.details.social.twitter',
                }
            },
            bottom_save_panel: {
                save_button: 'company.detail.properties.bottom_save_panel.save_button',
                updated_by_text: 'company.detail.properties.bottom_save_panel.updated_by_text',
            }
        }
    }
}