import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'calculateAgePipe'
})
export class CalculateAgePipe implements PipeTransform {
    transform(value: string, ...args: any[]) {
        if (value) {
            const todayDate = new Date()
            const birthday = new Date(value)
            const ageInMs = todayDate.getTime() - birthday.getTime()
            const age = new Date(ageInMs)
            return (age.getFullYear() - 1970).toString()
        }
        else
            return '----'
    }
}