import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'calculateQuantityPipe'
})
export class CalculateQuantityPipe implements PipeTransform {
    transform(value: any[], ...args: any[]) {
        if (args[0]) {
            const countSettings = args[0] as { field: string, value: string }
            return value.filter(elm => elm[countSettings.field] === countSettings.value).length;
        } else {
            return value.length;
        }
    }
}