import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterChoiceOptionType } from "@dashboard-components/data-browser/models/filters.model";
import { filter, map, takeUntil } from "rxjs";
import { DestroyableSubscription } from "src/utils/destroyable-subscription";

@Component({
    selector: 'data-explorer-single-choice-filter',
    templateUrl: `./data-explorer-single-choice-filter.component.html`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DataExplorerSingleChoiceFilterComponent extends DestroyableSubscription implements OnInit {

    @Input() filterLabel: string;
    @Input() filterKey: string;
    @Input() options: FilterChoiceOptionType[];

    @Output() filterChange = new EventEmitter();

    filterForm: FormGroup;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.filterForm = new FormGroup({
            [this.filterKey + 'Selected']: new FormControl(false),
            [this.filterKey]: new FormControl()
        });

        this.filterForm.valueChanges.pipe(
            filter(formValue => formValue[this.filterKey] != undefined),
            map(formValue => ({ [this.filterKey]: formValue[this.filterKey].key, selected: formValue[this.filterKey + 'Selected'] })),
            takeUntil(this.ngUnsubscribe)
        ).subscribe(filter => this.filterChange.emit(filter));
    }
}