<form class="flex flex-column gap-3" [formGroup]="filterForm">
    <p-panel>
        <ng-template pTemplate="header">
            <p-checkbox [formControlName]="filterKey+'Selected'" [binary]="true" inputId="binary"></p-checkbox>
            <span class="font-semibold">{{filterLabel}}</span>
        </ng-template>
        <div *ngFor="let option of options" class="field-checkbox">
            <p-radioButton [inputId]="option.key" [value]="option" [formControlName]="filterKey"></p-radioButton>
            <label [for]="option.key" class="ml-2">{{ option.name }}</label>
        </div>
    </p-panel>
</form>