const WINE_SHARED = {
    ciao: 'ciao'
}

export const WINE_TRANSLATIONS_IT = {
    wine: {
        list: {
            ciao: WINE_SHARED.ciao,
            table_title_label: 'Vini',
            table_new_item: 'Crea vino',
            columns: {
                full_name: 'Nome Completo',
                name: 'Nome',
                id: 'Id',
                color: 'colore',
                winery_id: 'Id cantina',
                country: 'Nazione',
                region: 'Regione',
                status: 'Stato',
                grapes: 'Uve',
                is_claimed_by_winery: 'Reclamato dalla cantina',
                description: 'Descrizione',
                acidity: 'Acidità',
                fizziness: 'Frizzantezza',
                intensity: 'Intensità',
                sweetness: 'Dolcezza',
                tannin: 'Tannicità',
                wine_types: 'Tipi',
                created_at: 'Creato il',
                updated_at: 'Aggiornato il',
                percentage: 'Percentuale'
            },
            filters: {
                wine_id: 'Id vino',
                created_by: 'Creato Da(User id)',
                wine_color: 'Colore',
                acidity: 'Acidità',
                created_at: 'Creato il'
            },
            main_filters: {
                all: 'Tutti',
                pending: 'In attesa',
                active: 'Attivi',
                inactive: 'Inattivi'
            },
            speed_dial: {
                export: 'Esporta',
                deduplicate: 'Deduplica',
                delete: 'Elimina',
            }
        },
        detail: {
            created_by_label: 'Creato da {{createdBy}} il {{createdAt}}',
            tabs: {
                properties: 'Proprietà',
                vintages: 'Annate',
                //logs: 'Logs'
            },
            properties: {
                wine_color: {
                    red: 'Rosso',
                    // rose: 'Rosè',
                    white: 'Bianco',
                },
                wine_types: {
                    sparkling: 'Frizzante',
                    fortified: 'Fortificato',
                    sweet: 'Dolce',
                },
                country_label: 'Paese',
                region_label: 'Regione',
                grapes_label: 'Uve',
                description: {
                    title: 'Descrizione vino',
                    paragraph: 'Master - (lingua: {{lang}})',
                },
                wine_profile: {
                    acidity: 'Acidità',
                    fizziness: 'Frizzantezza',
                    intensity: 'Intensità',
                    tannin: 'Tannicità',
                },
                save_panel: {
                    update_string: 'ultimo aggiornamento effettuato da {{updatedBy}} il {{updatedAt}}',
                    submit_button: 'salva',
                },
                status: {
                    active: 'Attivo',
                    inactive: 'Inattivo',
                    pending: 'In attesa',
                }
            },
            wine_vintages: {
                table_title_label: 'Annate vino',
                table_new_item: 'Aggiungi annata',
            },
            ciao: WINE_SHARED.ciao
        },
        main_page: {
            tabs: {
                wines: 'Vini',
                vintages: 'Annate'
            }
        }
    }
}