import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { BackendService } from 'src/services/backend.service';
import { DataBrowserConfig } from '../data-browser.component';
import { DATA_BROWSER_API_ACTIONS, DATA_BROWSER_GENERIC_ACTIONS } from './data-browser.actions';
import { DATA_BROWSER_SELECTORS } from './data-browser.selectors';
import { DataBrowserState, PageInfo } from './data-browser.state';

@Injectable()
export class DataBrowserEffects {

    constructor(private actions$: Actions, private _store: Store<DataBrowserState>,
        private _backendService: BackendService) {
    }

    private _handleFilters(config: DataBrowserConfig, filtersObj: any, pageInfo: PageInfo): string {
        const filters = cloneDeep(filtersObj);
        if (Object.keys(filters).length === 0) {
            return `${config.endpoint}?page=${pageInfo.page}&limit=${pageInfo.size}`;
        }
        let endpoint = `${config.endpoint}?`;
        const searchText = filters.searchText;
        delete filters.searchText;
        const filtersKey = Object.keys(filters);
        if (searchText?.length > 0) {
            endpoint += `${config.searchField}=${searchText}`
            if (filtersKey.length === 0) {
                return `${endpoint}&page=${pageInfo.page}&limit=${pageInfo.size}`;
            }
            endpoint += '&';
        }
        const stringFilters = [];
        for (const key of filtersKey) {
            stringFilters.push(`${key}=${Array.isArray(filters[key]) ? filters[key].join(',') : filters[key]}`);
        }
        return endpoint + stringFilters.join('&') + `&page=${pageInfo.page}&limit=${pageInfo.size}`;
    }

    getItems = createEffect(() =>
        this.actions$.pipe(
            ofType(DATA_BROWSER_GENERIC_ACTIONS.getItems),
            mergeMap(action =>
                combineLatest([
                    of(action),
                    this._store.select(DATA_BROWSER_SELECTORS(action.featureName).selectDataBrowserConfig)
                ])
            ),
            switchMap(([{ featureName, filters, pageInfo }, config]) => {
                return this._backendService.get<any>(this._handleFilters(config, filters, pageInfo)).pipe(
                    map(response => DATA_BROWSER_API_ACTIONS(featureName).getItemsSuccess({ items: response[config.responseField], pageInfo: { page: response.page, pages: response.pages, size: pageInfo.size } })),
                    catchError(err => of(DATA_BROWSER_API_ACTIONS(featureName).getItemsFailure({ error: err.message })))
                )
            }
            )));

}